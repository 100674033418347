@import url(https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Montserrat%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Black+Ops+One%3Aital%2Cwght%400%2C400&family=Special+Elite%3Aital%2Cwght%400%2C400&family=Ultra%3Aital%2Cwght%400%2C400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.plasmic_mechz_plasmic_tokens__qawxJ {
  --token-7c4lS8m-txGF1z: #3291ff;
  --plasmic-token-menu-color: var(--token-7c4lS8m-txGF1z);
  --token-Jm9Nk06qypQ6X5: #000000;
  --plasmic-token-unnamed-style-token-2: var(--token-Jm9Nk06qypQ6X5);
  --token-Ksk4fqeEjc9aLu: #ffffff;
  --plasmic-token-heading-color: var(--token-Ksk4fqeEjc9aLu);
  --token-sBEzcCWfasKtfB: 1px;
  --plasmic-token-unnamed-style-token: var(--token-sBEzcCWfasKtfB);
  --token-AJG1E7gV1cZl6J: 100%;
  --plasmic-token-auto: var(--token-AJG1E7gV1cZl6J);
  --token-0lnNLXXS74g494: 100%;
  --plasmic-token-auto-2: var(--token-0lnNLXXS74g494);
  --token-hf6SpfXAWY0fhG: #2c343c;
  --plasmic-token-unnamed-style-token-3: var(--token-hf6SpfXAWY0fhG);
  --token-8ywlb8zRP3SQjb: var(--token-Jm9Nk06qypQ6X5);
  --plasmic-token-unnamed-style-token-4: var(--token-8ywlb8zRP3SQjb);
}

.plasmic_mechz_plasmic_default_styles__8IHxk {
  --mixin-DVLYj4jsG-v_1L_font-family: "Inter";
  --mixin-DVLYj4jsG-v_1L_font-size: 16px;
  --mixin-DVLYj4jsG-v_1L_font-weight: 400;
  --mixin-DVLYj4jsG-v_1L_font-style: normal;
  --mixin-DVLYj4jsG-v_1L_color: #535353;
  --mixin-DVLYj4jsG-v_1L_text-align: left;
  --mixin-DVLYj4jsG-v_1L_text-transform: none;
  --mixin-DVLYj4jsG-v_1L_line-height: 1.5;
  --mixin-DVLYj4jsG-v_1L_letter-spacing: normal;
  --mixin-DVLYj4jsG-v_1L_white-space: pre-wrap;
  --mixin-DVLYj4jsG-v_1L_user-select: text;
  --mixin-DVLYj4jsG-v_1L_text-decoration-line: none;
  --mixin-DVLYj4jsG-v_1L_text-overflow: clip;
  --mixin-kM4yPY4OKGYpVt_color: #000000;
  --mixin-kM4yPY4OKGYpVt_font-weight: 900;
  --mixin-kM4yPY4OKGYpVt_font-size: 72px;
  --mixin-kM4yPY4OKGYpVt_line-height: 1;
  --mixin-kM4yPY4OKGYpVt_letter-spacing: -4px;
  --mixin-kM4yPY4OKGYpVt_white-space: pre-wrap;
  --mixin-JBn16J9kRVSlXE_color: #000000;
  --mixin-JBn16J9kRVSlXE_font-weight: 700;
  --mixin-JBn16J9kRVSlXE_font-size: 48px;
  --mixin-JBn16J9kRVSlXE_line-height: 1.1;
  --mixin-JBn16J9kRVSlXE_letter-spacing: -1px;
  --mixin-JBn16J9kRVSlXE_white-space: pre-wrap;
  --mixin-mLJuneT0158ynP_color: #3291ff;
  --mixin-mLJuneT0158ynP_white-space: pre-wrap;
  --mixin-ZOBT59kWkSEUlb_color: #000000;
  --mixin-ZOBT59kWkSEUlb_font-weight: 600;
  --mixin-ZOBT59kWkSEUlb_font-size: 32px;
  --mixin-ZOBT59kWkSEUlb_line-height: 1.2;
  --mixin-ZOBT59kWkSEUlb_letter-spacing: -0.8px;
  --mixin-ZOBT59kWkSEUlb_white-space: pre-wrap;
  --mixin-Nmy1zqr2O_To-6_font-family: "Inter";
  --mixin-Nmy1zqr2O_To-6_color: #000000;
  --mixin-Nmy1zqr2O_To-6_font-size: 24px;
  --mixin-Nmy1zqr2O_To-6_font-weight: 600;
  --mixin-Nmy1zqr2O_To-6_letter-spacing: -0.5px;
  --mixin-Nmy1zqr2O_To-6_line-height: 1.3;
  --mixin-Nmy1zqr2O_To-6_white-space: pre-wrap;
  --mixin-XFgalk5OThDS19_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-XFgalk5OThDS19_border-bottom-color: #dddddd;
  --mixin-XFgalk5OThDS19_border-bottom-style: solid;
  --mixin-XFgalk5OThDS19_border-bottom-width: 1px;
  --mixin-XFgalk5OThDS19_border-left-color: #dddddd;
  --mixin-XFgalk5OThDS19_border-left-style: solid;
  --mixin-XFgalk5OThDS19_border-left-width: 1px;
  --mixin-XFgalk5OThDS19_border-right-color: #dddddd;
  --mixin-XFgalk5OThDS19_border-right-style: solid;
  --mixin-XFgalk5OThDS19_border-right-width: 1px;
  --mixin-XFgalk5OThDS19_border-top-color: #dddddd;
  --mixin-XFgalk5OThDS19_border-top-style: solid;
  --mixin-XFgalk5OThDS19_border-top-width: 1px;
  --mixin-XFgalk5OThDS19_border-bottom-left-radius: 3px;
  --mixin-XFgalk5OThDS19_border-bottom-right-radius: 3px;
  --mixin-XFgalk5OThDS19_border-top-left-radius: 3px;
  --mixin-XFgalk5OThDS19_border-top-right-radius: 3px;
  --mixin-XFgalk5OThDS19_font-family: "Inconsolata";
  --mixin-XFgalk5OThDS19_padding-bottom: 1px;
  --mixin-XFgalk5OThDS19_padding-left: 4px;
  --mixin-XFgalk5OThDS19_padding-right: 4px;
  --mixin-XFgalk5OThDS19_padding-top: 1px;
  --mixin-XFgalk5OThDS19_white-space: pre-wrap;
  --mixin-XJB99u9y94E2kP_font-family: "Inter";
  --mixin-XJB99u9y94E2kP_color: #000000;
  --mixin-XJB99u9y94E2kP_font-size: 20px;
  --mixin-XJB99u9y94E2kP_font-weight: 600;
  --mixin-XJB99u9y94E2kP_letter-spacing: -0.3px;
  --mixin-XJB99u9y94E2kP_line-height: 1.5;
  --mixin-XJB99u9y94E2kP_white-space: pre-wrap;
  --mixin-ZG-6hC78tsCO_q_font-family: "Inter";
  --mixin-ZG-6hC78tsCO_q_color: #000000;
  --mixin-ZG-6hC78tsCO_q_font-size: 16px;
  --mixin-ZG-6hC78tsCO_q_font-weight: 600;
  --mixin-ZG-6hC78tsCO_q_line-height: 1.5;
  --mixin-ZG-6hC78tsCO_q_white-space: pre-wrap;
  --mixin-VYlqG81qk-rAaq_border-left-color: #dddddd;
  --mixin-VYlqG81qk-rAaq_border-left-style: solid;
  --mixin-VYlqG81qk-rAaq_border-left-width: 3px;
  --mixin-VYlqG81qk-rAaq_color: #888888;
  --mixin-VYlqG81qk-rAaq_padding-left: 10px;
  --mixin-VYlqG81qk-rAaq_white-space: pre-wrap;
  --mixin-dm6JnV4VbAQhID_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-dm6JnV4VbAQhID_border-bottom-color: #dddddd;
  --mixin-dm6JnV4VbAQhID_border-bottom-style: solid;
  --mixin-dm6JnV4VbAQhID_border-bottom-width: 1px;
  --mixin-dm6JnV4VbAQhID_border-left-color: #dddddd;
  --mixin-dm6JnV4VbAQhID_border-left-style: solid;
  --mixin-dm6JnV4VbAQhID_border-left-width: 1px;
  --mixin-dm6JnV4VbAQhID_border-right-color: #dddddd;
  --mixin-dm6JnV4VbAQhID_border-right-style: solid;
  --mixin-dm6JnV4VbAQhID_border-right-width: 1px;
  --mixin-dm6JnV4VbAQhID_border-top-color: #dddddd;
  --mixin-dm6JnV4VbAQhID_border-top-style: solid;
  --mixin-dm6JnV4VbAQhID_border-top-width: 1px;
  --mixin-dm6JnV4VbAQhID_border-bottom-left-radius: 3px;
  --mixin-dm6JnV4VbAQhID_border-bottom-right-radius: 3px;
  --mixin-dm6JnV4VbAQhID_border-top-left-radius: 3px;
  --mixin-dm6JnV4VbAQhID_border-top-right-radius: 3px;
  --mixin-dm6JnV4VbAQhID_font-family: "Inconsolata";
  --mixin-dm6JnV4VbAQhID_padding-bottom: 3px;
  --mixin-dm6JnV4VbAQhID_padding-left: 6px;
  --mixin-dm6JnV4VbAQhID_padding-right: 6px;
  --mixin-dm6JnV4VbAQhID_padding-top: 3px;
  --mixin-dm6JnV4VbAQhID_white-space: pre-wrap;
  --mixin-bfwmAKorQIK6JL_display: flex;
  --mixin-bfwmAKorQIK6JL_flex-direction: column;
  --mixin-bfwmAKorQIK6JL_align-items: stretch;
  --mixin-bfwmAKorQIK6JL_justify-content: flex-start;
  --mixin-bfwmAKorQIK6JL_list-style-position: outside;
  --mixin-bfwmAKorQIK6JL_padding-left: 40px;
  --mixin-bfwmAKorQIK6JL_position: relative;
  --mixin-bfwmAKorQIK6JL_list-style-type: decimal;
  --mixin-bfwmAKorQIK6JL_white-space: pre-wrap;
}

.plasmic_mechz_plasmic_mixins__2W_xi {
  --mixin-3yZFZ-mZexCbSE_box-shadow: 0px 4px 16px 0px #00000033;
  --plasmic-mixin-top-btn_box-shadow: var(--mixin-3yZFZ-mZexCbSE_box-shadow);
}

:where(.plasmic_mechz_all__2vcVD) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.plasmic_mechz_img__H_4Iv) {
  display: inline-block;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s img) {
  white-space: inherit;
}

:where(.plasmic_mechz_li__29r4T) {
  display: list-item;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s li) {
  white-space: inherit;
}

:where(.plasmic_mechz_span__25kVl) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_mechz_input__3I3Hq) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_mechz_textarea__3Th8c) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_mechz_button__31rzK) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.plasmic_mechz_code__3NQBw) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_mechz_pre__OQULF) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_mechz_p__j4VYy) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_mechz_h1__1K1jK) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_mechz_h2__3mLqu) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_mechz_h3__1gH3O) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_mechz_h4__bpROY) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_mechz_h5__2H_yq) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_mechz_h6__hB6Re) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_mechz_address__2xdYk) {
  font-style: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.plasmic_mechz_a__22GiQ) {
  color: inherit;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s a) {
  white-space: inherit;
  color: inherit;
}

:where(.plasmic_mechz_ol__3OJ-T) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_mechz_ul__2oRzU) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_mechz_select__3X76N) {
  padding: 2px 6px;
}
:where(.plasmic_mechz___wab_expr_html_text__2vd_s select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_mechz_plasmic_default__component_wrapper__uRSZz {
  display: grid;
}
.plasmic_mechz_plasmic_default__inline__3DfJo {
  display: inline;
}
.plasmic_mechz_plasmic_page_wrapper__1CiXj {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_mechz_plasmic_page_wrapper__1CiXj > * {
  height: auto !important;
}
.plasmic_mechz___wab_expr_html_text__2vd_s {
  white-space: normal;
}
.plasmic_mechz_root_reset__1SAsh {
  font-family: var(--mixin-DVLYj4jsG-v_1L_font-family);
  font-size: var(--mixin-DVLYj4jsG-v_1L_font-size);
  font-weight: var(--mixin-DVLYj4jsG-v_1L_font-weight);
  font-style: var(--mixin-DVLYj4jsG-v_1L_font-style);
  color: var(--mixin-DVLYj4jsG-v_1L_color);
  text-align: var(--mixin-DVLYj4jsG-v_1L_text-align);
  text-transform: var(--mixin-DVLYj4jsG-v_1L_text-transform);
  line-height: var(--mixin-DVLYj4jsG-v_1L_line-height);
  letter-spacing: var(--mixin-DVLYj4jsG-v_1L_letter-spacing);
  white-space: var(--mixin-DVLYj4jsG-v_1L_white-space);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__h1__2ViuV),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_h1__1K1jK),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s h1),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__h1__2ViuV) {
  color: var(--mixin-kM4yPY4OKGYpVt_color);
  font-weight: var(--mixin-kM4yPY4OKGYpVt_font-weight);
  font-size: var(--mixin-kM4yPY4OKGYpVt_font-size);
  line-height: var(--mixin-kM4yPY4OKGYpVt_line-height);
  letter-spacing: var(--mixin-kM4yPY4OKGYpVt_letter-spacing);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__h2__3yAbg),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_h2__3mLqu),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s h2),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__h2__3yAbg) {
  color: var(--mixin-JBn16J9kRVSlXE_color);
  font-weight: var(--mixin-JBn16J9kRVSlXE_font-weight);
  font-size: var(--mixin-JBn16J9kRVSlXE_font-size);
  line-height: var(--mixin-JBn16J9kRVSlXE_line-height);
  letter-spacing: var(--mixin-JBn16J9kRVSlXE_letter-spacing);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__a__1Pp69),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_a__22GiQ),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s a),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__a__1Pp69) {
  color: var(--mixin-mLJuneT0158ynP_color);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__h3__cpqyt),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_h3__1gH3O),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s h3),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__h3__cpqyt) {
  color: var(--mixin-ZOBT59kWkSEUlb_color);
  font-weight: var(--mixin-ZOBT59kWkSEUlb_font-weight);
  font-size: var(--mixin-ZOBT59kWkSEUlb_font-size);
  line-height: var(--mixin-ZOBT59kWkSEUlb_line-height);
  letter-spacing: var(--mixin-ZOBT59kWkSEUlb_letter-spacing);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__h4__D-JmU),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_h4__bpROY),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s h4),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__h4__D-JmU) {
  font-family: var(--mixin-Nmy1zqr2O_To-6_font-family);
  color: var(--mixin-Nmy1zqr2O_To-6_color);
  font-size: var(--mixin-Nmy1zqr2O_To-6_font-size);
  font-weight: var(--mixin-Nmy1zqr2O_To-6_font-weight);
  letter-spacing: var(--mixin-Nmy1zqr2O_To-6_letter-spacing);
  line-height: var(--mixin-Nmy1zqr2O_To-6_line-height);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__code__WLe83),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_code__3NQBw),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s code),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__code__WLe83) {
  background: var(--mixin-XFgalk5OThDS19_background);
  font-family: var(--mixin-XFgalk5OThDS19_font-family);
  border-radius: var(--mixin-XFgalk5OThDS19_border-top-left-radius)
    var(--mixin-XFgalk5OThDS19_border-top-right-radius)
    var(--mixin-XFgalk5OThDS19_border-bottom-right-radius)
    var(--mixin-XFgalk5OThDS19_border-bottom-left-radius);
  padding: var(--mixin-XFgalk5OThDS19_padding-top)
    var(--mixin-XFgalk5OThDS19_padding-right)
    var(--mixin-XFgalk5OThDS19_padding-bottom)
    var(--mixin-XFgalk5OThDS19_padding-left);
  border-top: var(--mixin-XFgalk5OThDS19_border-top-width)
    var(--mixin-XFgalk5OThDS19_border-top-style)
    var(--mixin-XFgalk5OThDS19_border-top-color);
  border-right: var(--mixin-XFgalk5OThDS19_border-right-width)
    var(--mixin-XFgalk5OThDS19_border-right-style)
    var(--mixin-XFgalk5OThDS19_border-right-color);
  border-bottom: var(--mixin-XFgalk5OThDS19_border-bottom-width)
    var(--mixin-XFgalk5OThDS19_border-bottom-style)
    var(--mixin-XFgalk5OThDS19_border-bottom-color);
  border-left: var(--mixin-XFgalk5OThDS19_border-left-width)
    var(--mixin-XFgalk5OThDS19_border-left-style)
    var(--mixin-XFgalk5OThDS19_border-left-color);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__h5__1RxYP),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_h5__2H_yq),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s h5),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__h5__1RxYP) {
  font-family: var(--mixin-XJB99u9y94E2kP_font-family);
  color: var(--mixin-XJB99u9y94E2kP_color);
  font-size: var(--mixin-XJB99u9y94E2kP_font-size);
  font-weight: var(--mixin-XJB99u9y94E2kP_font-weight);
  letter-spacing: var(--mixin-XJB99u9y94E2kP_letter-spacing);
  line-height: var(--mixin-XJB99u9y94E2kP_line-height);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__h6__2JpoC),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_h6__hB6Re),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s h6),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__h6__2JpoC) {
  font-family: var(--mixin-ZG-6hC78tsCO_q_font-family);
  color: var(--mixin-ZG-6hC78tsCO_q_color);
  font-size: var(--mixin-ZG-6hC78tsCO_q_font-size);
  font-weight: var(--mixin-ZG-6hC78tsCO_q_font-weight);
  line-height: var(--mixin-ZG-6hC78tsCO_q_line-height);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__blockquote__2BIrS),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_blockquote__t-FfG),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s blockquote),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__blockquote__2BIrS) {
  color: var(--mixin-VYlqG81qk-rAaq_color);
  padding-left: var(--mixin-VYlqG81qk-rAaq_padding-left);
  border-left: var(--mixin-VYlqG81qk-rAaq_border-left-width)
    var(--mixin-VYlqG81qk-rAaq_border-left-style)
    var(--mixin-VYlqG81qk-rAaq_border-left-color);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__pre__5oWni),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_pre__OQULF),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s pre),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__pre__5oWni) {
  background: var(--mixin-dm6JnV4VbAQhID_background);
  font-family: var(--mixin-dm6JnV4VbAQhID_font-family);
  border-radius: var(--mixin-dm6JnV4VbAQhID_border-top-left-radius)
    var(--mixin-dm6JnV4VbAQhID_border-top-right-radius)
    var(--mixin-dm6JnV4VbAQhID_border-bottom-right-radius)
    var(--mixin-dm6JnV4VbAQhID_border-bottom-left-radius);
  padding: var(--mixin-dm6JnV4VbAQhID_padding-top)
    var(--mixin-dm6JnV4VbAQhID_padding-right)
    var(--mixin-dm6JnV4VbAQhID_padding-bottom)
    var(--mixin-dm6JnV4VbAQhID_padding-left);
  border-top: var(--mixin-dm6JnV4VbAQhID_border-top-width)
    var(--mixin-dm6JnV4VbAQhID_border-top-style)
    var(--mixin-dm6JnV4VbAQhID_border-top-color);
  border-right: var(--mixin-dm6JnV4VbAQhID_border-right-width)
    var(--mixin-dm6JnV4VbAQhID_border-right-style)
    var(--mixin-dm6JnV4VbAQhID_border-right-color);
  border-bottom: var(--mixin-dm6JnV4VbAQhID_border-bottom-width)
    var(--mixin-dm6JnV4VbAQhID_border-bottom-style)
    var(--mixin-dm6JnV4VbAQhID_border-bottom-color);
  border-left: var(--mixin-dm6JnV4VbAQhID_border-left-width)
    var(--mixin-dm6JnV4VbAQhID_border-left-style)
    var(--mixin-dm6JnV4VbAQhID_border-left-color);
}

:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_plasmic_default__ol__EwIFX),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz_ol__3OJ-T),
:where(.plasmic_mechz_root_reset__1SAsh .plasmic_mechz___wab_expr_html_text__2vd_s ol),
:where(.plasmic_mechz_root_reset__1SAsh.plasmic_mechz_plasmic_default__ol__EwIFX) {
  display: var(--mixin-bfwmAKorQIK6JL_display);
  flex-direction: var(--mixin-bfwmAKorQIK6JL_flex-direction);
  align-items: var(--mixin-bfwmAKorQIK6JL_align-items);
  justify-content: var(--mixin-bfwmAKorQIK6JL_justify-content);
  list-style-position: var(--mixin-bfwmAKorQIK6JL_list-style-position);
  padding-left: var(--mixin-bfwmAKorQIK6JL_padding-left);
  position: var(--mixin-bfwmAKorQIK6JL_position);
  list-style-type: var(--mixin-bfwmAKorQIK6JL_list-style-type);
  flex-column-gap: var(--mixin-bfwmAKorQIK6JL_flex-column-gap);
}

.PlasmicButton_root__1G89j {
  flex-direction: row;
  display: flex;
  position: relative;
  background: #232320;
  cursor: pointer;
  transition-property: background;
  transition-duration: 0.1s;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.1s;
  border-radius: 6px;
  padding: 12px 20px;
  border-width: 0px;
}
.PlasmicButton_root__1G89j > .__wab_flex-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.PlasmicButton_root__1G89j > .__wab_flex-container > *,
.PlasmicButton_root__1G89j > .__wab_flex-container > .__wab_slot > *,
.PlasmicButton_root__1G89j > .__wab_flex-container > picture > img,
.PlasmicButton_root__1G89j > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 8px;
}
.PlasmicButton_rootshowStartIcon__1RkGQ {
  padding-left: 16px;
}
.PlasmicButton_rootcolor_green__pjpFr {
  background: #30a46c;
}
.PlasmicButton_rootcolor_yellow__3gFoh {
  background: #f5d90a;
}
.PlasmicButton_rootcolor_red__23QWf {
  background: #e54d2e;
}
.PlasmicButton_rootcolor_sand__1y1T2 {
  background: #717069;
}
.PlasmicButton_rootcolor_white__2XAvg {
  background: #ffffff;
}
.PlasmicButton_rootcolor_softBlue__1UkU- {
  background: #edf6ff;
}
.PlasmicButton_rootcolor_softGreen__1oTno {
  background: #e9f9ee;
}
.PlasmicButton_rootcolor_softYellow__3BdGT {
  background: #fffbd1;
}
.PlasmicButton_rootcolor_softRed__2oESo {
  background: #fff0ee;
}
.PlasmicButton_rootcolor_softSand__211Gp {
  background: #eeeeec;
}
.PlasmicButton_rootshowEndIcon__2Xj0G {
  padding-right: 16px;
}
.PlasmicButton_rootcolor_clear__2GLCQ {
  background: #ffffff00;
}
.PlasmicButton_rootcolor_link__2qJX1 {
  background: #ffffff00;
}
.PlasmicButton_rootisDisabled__3biVX {
  cursor: not-allowed;
  opacity: 0.6;
}
.PlasmicButton_rootshape_rounded__Dowmr {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 100px;
  border-radius: 999px;
}
.PlasmicButton_rootshape_round__2my6M {
  border-radius: 50%;
  padding: 12px;
}
.PlasmicButton_rootshape_sharp__2oQSD {
  border-radius: 0px;
}
.PlasmicButton_rootsize_compact__25rPt {
  padding: 6px 12px;
}
.PlasmicButton_rootsize_minimal__2Bg13 {
  padding: 0px;
}
.PlasmicButton_rootcolor_blue__hzRRf {
  background: #0091ff;
}
.PlasmicButton_rootshape_round_size_compact__1GN_F {
  padding: 6px;
}
.PlasmicButton_rootshowStartIcon_shape_rounded__3jvHN {
  padding-left: 16px;
}
.PlasmicButton_rootshowEndIcon_shape_rounded__1mcVx {
  padding-right: 16px;
}
.PlasmicButton_rootshowStartIcon_size_compact__3x_Wp {
  padding-left: 8px;
}
.PlasmicButton_rootsize_compact_showEndIcon__17HK7 {
  padding-right: 8px;
}
.PlasmicButton_root__1G89j:hover {
  background: #282826;
}
.PlasmicButton_rootcolor_green__pjpFr:hover {
  background: #3cb179;
}
.PlasmicButton_rootcolor_yellow__3gFoh:hover {
  background: #ffef5c;
}
.PlasmicButton_rootcolor_red__23QWf:hover {
  background: #ec5e41;
}
.PlasmicButton_rootcolor_sand__1y1T2:hover {
  background: #7f7e77;
}
.PlasmicButton_rootcolor_white__2XAvg:hover {
  background: #ffef5c;
}
.PlasmicButton_rootcolor_softBlue__1UkU-:hover {
  background: #e1f0ff;
}
.PlasmicButton_rootcolor_softGreen__1oTno:hover {
  background: #ddf3e4;
}
.PlasmicButton_rootcolor_softYellow__3BdGT:hover {
  background: #fff8bb;
}
.PlasmicButton_rootcolor_softRed__2oESo:hover {
  background: #ffe6e2;
}
.PlasmicButton_rootcolor_softSand__211Gp:hover {
  background: #e9e9e6;
}
.PlasmicButton_rootcolor_clear__2GLCQ:hover {
  background: #e9e9e6;
}
.PlasmicButton_rootcolor_link__2qJX1:hover {
  background: #ffffff00;
}
.PlasmicButton_rootcolor_blue__hzRRf:hover {
  background: #369eff;
}
.PlasmicButton_root__1G89j:focus-within {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.PlasmicButton_root___focusVisibleWithin__2pUM- {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.PlasmicButton_root__1G89j:active {
  background: #2e2e2b;
}
.PlasmicButton_rootcolor_green__pjpFr:active {
  background: #4cc38a;
}
.PlasmicButton_rootcolor_yellow__3gFoh:active {
  background: #f0c000;
}
.PlasmicButton_rootcolor_red__23QWf:active {
  background: #f16a50;
}
.PlasmicButton_rootcolor_sand__1y1T2:active {
  background: #a1a09a;
}
.PlasmicButton_rootcolor_white__2XAvg:active {
  background: #f0c000;
}
.PlasmicButton_rootcolor_softBlue__1UkU-:active {
  background: #cee7fe;
}
.PlasmicButton_rootcolor_softGreen__1oTno:active {
  background: #ccebd7;
}
.PlasmicButton_rootcolor_softYellow__3BdGT:active {
  background: #fef2a4;
}
.PlasmicButton_rootcolor_softRed__2oESo:active {
  background: #fdd8d3;
}
.PlasmicButton_rootcolor_softSand__211Gp:active {
  background: #e3e3e0;
}
.PlasmicButton_rootcolor_clear__2GLCQ:active {
  background: #e3e3e0;
}
.PlasmicButton_rootcolor_link__2qJX1:active {
  background: #ffffff00;
}
.PlasmicButton_rootcolor_blue__hzRRf:active {
  background: #52a9ff;
}
.PlasmicButton_startIconContainer__3x_N2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicButton_startIconContainershowStartIcon__2nLSe {
  display: flex;
}
.PlasmicButton_slotTargetStartIcon__2YCpC {
  color: #ededec;
}
.PlasmicButton_slotTargetStartIconcolor_yellow__Tn9I0 {
  color: #35290f;
}
.PlasmicButton_slotTargetStartIconcolor_white__2ZsHy {
  color: #35290f;
}
.PlasmicButton_slotTargetStartIconcolor_softBlue__fcOto {
  color: #006adc;
}
.PlasmicButton_slotTargetStartIconcolor_softGreen__1kTbe {
  color: #18794e;
}
.PlasmicButton_slotTargetStartIconcolor_softYellow__592XC {
  color: #946800;
}
.PlasmicButton_slotTargetStartIconcolor_softRed__2tGsk {
  color: #ca3214;
}
.PlasmicButton_slotTargetStartIconcolor_softSand__3Ee6g {
  color: #706f6c;
}
.PlasmicButton_slotTargetStartIconcolor_clear__39JHE {
  color: #1b1b18;
}
.PlasmicButton_slotTargetStartIconcolor_link__22Is5 {
  color: #0091ff;
}
.PlasmicButton_rootcolor_link__2qJX1:hover .PlasmicButton_slotTargetStartIconcolor_link__22Is5 {
  color: #0081f1;
}
.PlasmicButton_rootcolor_link__2qJX1:active .PlasmicButton_slotTargetStartIconcolor_link__22Is5 {
  color: #006adc;
}
.PlasmicButton_svg__yT0Tl__1o1pH {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
}
.PlasmicButton_contentContainer__1F5jZ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicButton_root__1G89j .PlasmicButton_contentContainer___focusVisibleWithin__a55sB {
  outline: none;
}
.PlasmicButton_slotTargetChildren__1UWtk {
  color: #ededec;
  font-weight: 500;
  white-space: pre;
}
.PlasmicButton_slotTargetChildrencolor_green__BfrPo {
  color: #ffffff;
}
.PlasmicButton_slotTargetChildrencolor_yellow__3kazE {
  color: #35290f;
}
.PlasmicButton_slotTargetChildrencolor_red__1RRA3 {
  color: #ffffff;
}
.PlasmicButton_slotTargetChildrencolor_sand__1rDja {
  color: #ffffff;
}
.PlasmicButton_slotTargetChildrencolor_white__2_rxY {
  color: #1b1b18;
}
.PlasmicButton_slotTargetChildrencolor_softBlue__3jqD3 {
  color: #006adc;
}
.PlasmicButton_slotTargetChildrencolor_softGreen__2S1TG {
  color: #18794e;
}
.PlasmicButton_slotTargetChildrencolor_softYellow__2UVN9 {
  color: #946800;
}
.PlasmicButton_slotTargetChildrencolor_softRed__nEJ1c {
  color: #ca3214;
}
.PlasmicButton_slotTargetChildrencolor_softSand__3N2fD {
  color: #1b1b18;
}
.PlasmicButton_slotTargetChildrencolor_clear__1Vd3o {
  color: #1b1b18;
}
.PlasmicButton_slotTargetChildrencolor_link__2Wwc5 {
  color: #0091ff;
}
.PlasmicButton_slotTargetChildrencolor_blue__2eQnR {
  color: #ffffff;
}
.PlasmicButton_rootcolor_link__2qJX1:hover .PlasmicButton_slotTargetChildrencolor_link__2Wwc5 {
  color: #0081f1;
}
.PlasmicButton_rootcolor_link__2qJX1:hover .PlasmicButton_slotTargetChildrencolor_link__2Wwc5 > .PlasmicButton___wab_text__2tU1S,
.PlasmicButton_rootcolor_link__2qJX1:hover
  .PlasmicButton_slotTargetChildrencolor_link__2Wwc5
  > .PlasmicButton___wab_slot-string-wrapper__2IO_I,
.PlasmicButton_rootcolor_link__2qJX1:hover .PlasmicButton_slotTargetChildrencolor_link__2Wwc5 > .PlasmicButton___wab_slot__1472W > .PlasmicButton___wab_text__2tU1S,
.PlasmicButton_rootcolor_link__2qJX1:hover
  .PlasmicButton_slotTargetChildrencolor_link__2Wwc5
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot-string-wrapper__2IO_I,
.PlasmicButton_rootcolor_link__2qJX1:hover
  .PlasmicButton_slotTargetChildrencolor_link__2Wwc5
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_text__2tU1S,
.PlasmicButton_rootcolor_link__2qJX1:hover
  .PlasmicButton_slotTargetChildrencolor_link__2Wwc5
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot-string-wrapper__2IO_I,
.PlasmicButton_rootcolor_link__2qJX1:hover
  .PlasmicButton_slotTargetChildrencolor_link__2Wwc5
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_text__2tU1S,
.PlasmicButton_rootcolor_link__2qJX1:hover
  .PlasmicButton_slotTargetChildrencolor_link__2Wwc5
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot-string-wrapper__2IO_I {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.PlasmicButton_root__1G89j:focus-within .PlasmicButton_slotTargetChildren__1UWtk > *,
.PlasmicButton_root__1G89j:focus-within .PlasmicButton_slotTargetChildren__1UWtk > .PlasmicButton___wab_slot__1472W > *,
.PlasmicButton_root__1G89j:focus-within .PlasmicButton_slotTargetChildren__1UWtk > .PlasmicButton___wab_slot__1472W > .PlasmicButton___wab_slot__1472W > *,
.PlasmicButton_root__1G89j:focus-within
  .PlasmicButton_slotTargetChildren__1UWtk
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > *,
.PlasmicButton_root__1G89j:focus-within .PlasmicButton_slotTargetChildren__1UWtk > picture > img,
.PlasmicButton_root__1G89j:focus-within .PlasmicButton_slotTargetChildren__1UWtk > .PlasmicButton___wab_slot__1472W > picture > img,
.PlasmicButton_root__1G89j:focus-within
  .PlasmicButton_slotTargetChildren__1UWtk
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > picture
  > img,
.PlasmicButton_root__1G89j:focus-within
  .PlasmicButton_slotTargetChildren__1UWtk
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > picture
  > img {
  outline: none;
}
.PlasmicButton_root__1G89j .PlasmicButton_slotTargetChildren___focusVisibleWithin__3PsrW > *,
.PlasmicButton_root__1G89j .PlasmicButton_slotTargetChildren___focusVisibleWithin__3PsrW > .PlasmicButton___wab_slot__1472W > *,
.PlasmicButton_root__1G89j .PlasmicButton_slotTargetChildren___focusVisibleWithin__3PsrW > .PlasmicButton___wab_slot__1472W > .PlasmicButton___wab_slot__1472W > *,
.PlasmicButton_root__1G89j
  .PlasmicButton_slotTargetChildren___focusVisibleWithin__3PsrW
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > *,
.PlasmicButton_root__1G89j .PlasmicButton_slotTargetChildren___focusVisibleWithin__3PsrW > picture > img,
.PlasmicButton_root__1G89j .PlasmicButton_slotTargetChildren___focusVisibleWithin__3PsrW > .PlasmicButton___wab_slot__1472W > picture > img,
.PlasmicButton_root__1G89j
  .PlasmicButton_slotTargetChildren___focusVisibleWithin__3PsrW
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > picture
  > img,
.PlasmicButton_root__1G89j
  .PlasmicButton_slotTargetChildren___focusVisibleWithin__3PsrW
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > .PlasmicButton___wab_slot__1472W
  > picture
  > img {
  outline: none;
}
.PlasmicButton_rootcolor_link__2qJX1:active .PlasmicButton_slotTargetChildrencolor_link__2Wwc5 {
  color: #006adc;
}
.PlasmicButton_endIconContainer__3wyXh {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicButton_endIconContainershowEndIcon__1V9C5 {
  display: flex;
}
.PlasmicButton_slotTargetEndIcon__3HLim {
  color: #ededec;
}
.PlasmicButton_slotTargetEndIconcolor_yellow__ti1Rk {
  color: #35290f;
}
.PlasmicButton_slotTargetEndIconcolor_white__1JWSk {
  color: #35290f;
}
.PlasmicButton_slotTargetEndIconcolor_softBlue__22zdN {
  color: #006adc;
}
.PlasmicButton_slotTargetEndIconcolor_softGreen__ADKGc {
  color: #18794e;
}
.PlasmicButton_slotTargetEndIconcolor_softYellow__1NY0P {
  color: #946800;
}
.PlasmicButton_slotTargetEndIconcolor_softRed__3dyks {
  color: #ca3214;
}
.PlasmicButton_slotTargetEndIconcolor_softSand__3lFfr {
  color: #706f6c;
}
.PlasmicButton_slotTargetEndIconcolor_clear__1flUe {
  color: #1b1b18;
}
.PlasmicButton_slotTargetEndIconcolor_link__C8BCO {
  color: #0091ff;
}
.PlasmicButton_rootcolor_link__2qJX1:hover .PlasmicButton_slotTargetEndIconcolor_link__C8BCO {
  color: #0081f1;
}
.PlasmicButton_rootcolor_link__2qJX1:active .PlasmicButton_slotTargetEndIconcolor_link__C8BCO {
  color: #006adc;
}
.PlasmicButton_svg__wEeH__3eJF4 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
}

.PlasmicHomepage_root__2Yf03 {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--token-Jm9Nk06qypQ6X5);
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.PlasmicHomepage_root__2Yf03 > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.PlasmicHomepage_root__2Yf03 > .__wab_flex-container > *,
.PlasmicHomepage_root__2Yf03 > .__wab_flex-container > .__wab_slot > *,
.PlasmicHomepage_root__2Yf03 > .__wab_flex-container > picture > img,
.PlasmicHomepage_root__2Yf03 > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 16px;
}
.PlasmicHomepage_navBar__zzxnu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1440px;
  border-bottom-color: #eceef1;
  border-bottom-style: solid;
  min-width: 0;
  padding: 20px 56px 15px;
  border-width: 1px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_navBar__zzxnu {
    align-self: auto;
    justify-content: center;
    align-items: center;
    padding: 6px 14px;
  }
}
.PlasmicHomepage_logoWrap__2go4b {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 8px;
}
.PlasmicHomepage_logo__3-1rf {
  position: relative;
  object-fit: cover;
  max-width: 220px;
}
.PlasmicHomepage_logo__3-1rf > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_logo__3-1rf > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
.PlasmicHomepage_navmenu__vYD5Q {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 330px;
  flex-wrap: nowrap;
  align-self: auto;
  align-content: stretch;
  margin-left: 26px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_navmenu__vYD5Q {
    margin-left: 1px;
    padding-right: 0px;
    width: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    max-width: none;
    height: 100%;
    flex-wrap: nowrap;
    align-content: stretch;
    flex-row-gap: 0px;
    min-height: 0;
  }
}
.PlasmicHomepage_link3__2D5Pr {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  position: relative;
  width: auto;
  height: auto;
  align-self: auto;
  left: auto;
  top: auto;
  padding: 0px 15px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_link3__2D5Pr {
    font-size: 10px;
    left: auto;
    top: auto;
    padding-right: 8px;
    position: relative;
  }
}
.PlasmicHomepage_link2__8_xj5 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  position: relative;
  width: auto;
  height: auto;
  align-self: flex-start;
  left: auto;
  top: auto;
  padding: 0px 15px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_link2__8_xj5 {
    font-size: 10px;
    left: auto;
    top: auto;
    padding-right: 5px;
    position: relative;
    align-self: auto;
  }
}
.PlasmicHomepage_link4__2wzTq {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  position: relative;
  width: auto;
  height: auto;
  align-self: flex-start;
  left: auto;
  top: auto;
  padding: 0px 15px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_link4__2wzTq {
    font-size: 10px;
    left: auto;
    top: auto;
    padding-right: 5px;
    position: relative;
    align-self: auto;
  }
}
.PlasmicHomepage_navBarButtonBlock__3rho5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_navBarButtonBlock__3rho5 {
    margin-left: 0px;
  }
}
.PlasmicHomepage_startPlayButton__3HbzH.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PlasmicHomepage_svg__bjomj__3LVWL {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicHomepage_btnText__YlX76 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_btnText__YlX76 {
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.PlasmicHomepage_svg__xt8Xo__1N6Vd {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicHomepage_section1__7Tu30 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 80px;
  align-self: flex-end;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_section1__7Tu30 {
    padding-top: 70px;
  }
}
.PlasmicHomepage_container__2CPQ2 {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_container__2CPQ2 {
    width: auto;
  }
}
.PlasmicHomepage_flexBox__161tL {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  flex-shrink: 1;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_flexBox__161tL > .__wab_flex-container {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.PlasmicHomepage_flexBox__161tL > .__wab_flex-container > :nth-child(2n + 1) {
  width: calc((100% - 2 * 16px) * 5 / 12);
}
.PlasmicHomepage_flexBox__161tL > .__wab_flex-container > :nth-child(2n + 2) {
  width: calc((100% - 2 * 16px) * 7 / 12);
}
.PlasmicHomepage_flexBox__161tL > .__wab_flex-container > *,
.PlasmicHomepage_flexBox__161tL > .__wab_flex-container > .__wab_slot > *,
.PlasmicHomepage_flexBox__161tL > .__wab_flex-container > picture > img,
.PlasmicHomepage_flexBox__161tL
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_flexBox__161tL {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_flexBox__161tL > .__wab_flex-container {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_flexBox__161tL > .__wab_flex-container > :nth-child(1n + 1) {
    width: calc((100% - 1 * 16px) * 12 / 12);
  }
}
.PlasmicHomepage_column__qu6Jm__38rCN {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_bannerImage__3LGuX {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  max-height: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_bannerImage__3LGuX {
    align-self: center;
  }
}
.PlasmicHomepage_img__rcv4N__d9XOQ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 4px 8px 24px 0px #00000080;
}
.PlasmicHomepage_img__rcv4N__d9XOQ > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_img__rcv4N__d9XOQ > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
.PlasmicHomepage_column__rVnyS__1xEYz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_contentBlock__180E5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px 8px 8px;
}
.PlasmicHomepage_mainHeading__I7ox_ {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 40px;
  font-weight: 700;
  font-family: "Black Ops One";
  line-height: 1.2;
  letter-spacing: 0px;
  position: relative;
  height: auto;
  max-width: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_mainHeading__I7ox_ {
    font-size: 46px;
  }
}
.PlasmicHomepage_peraBanner__XbgKF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.PlasmicHomepage_text__jpy6S__2VYPb {
  position: relative;
  font-family: "Special Elite";
  font-size: 24px;
  font-weight: 500;
  color: var(--token-Ksk4fqeEjc9aLu);
  letter-spacing: 1px;
  padding-bottom: 9px;
  padding-top: 0px;
}
.PlasmicHomepage_freeBox___9V5Y5__170sr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  min-width: 0;
  padding: 0px;
  margin: 20px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_freeBox___9V5Y5__170sr {
    top: auto;
    left: auto;
    align-self: center;
  }
}
.PlasmicHomepage_playGameButton__ZB1DV.__wab_instance {
  max-width: 100%;
}
.PlasmicHomepage_svg__kStC__Idjvc {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicHomepage_text__aJrBk__fY6Lv {
  height: auto;
}
.PlasmicHomepage_svg___56Mgu__2GzIr {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicHomepage_section2__2Zu4q {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  min-width: 0;
}
.PlasmicHomepage_section2__2Zu4q > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 60px);
  height: calc(100% + 60px);
}
.PlasmicHomepage_section2__2Zu4q > .__wab_flex-container > *,
.PlasmicHomepage_section2__2Zu4q > .__wab_flex-container > .__wab_slot > *,
.PlasmicHomepage_section2__2Zu4q > .__wab_flex-container > picture > img,
.PlasmicHomepage_section2__2Zu4q
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 60px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_section2__2Zu4q {
    width: var(--token-AJG1E7gV1cZl6J);
    align-self: auto;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_section2__2Zu4q > .__wab_flex-container {
    min-height: 0;
  }
}
.PlasmicHomepage_subHeading__3R9SU {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_subHeading__3R9SU {
    left: 0px;
    text-align: center;
    width: 100%;
    font-size: 30px;
    min-width: 0;
  }
}
.PlasmicHomepage_logoContainer__3vzZf {
  flex-direction: row;
  width: 1350px;
  max-width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
}
.PlasmicHomepage_logoContainer__3vzZf > .__wab_flex-container {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-left: calc(0px - 90px);
  width: calc(100% + 90px);
  margin-top: calc(0px - 34px);
  height: calc(100% + 34px);
}
.PlasmicHomepage_logoContainer__3vzZf > .__wab_flex-container > *,
.PlasmicHomepage_logoContainer__3vzZf > .__wab_flex-container > .__wab_slot > *,
.PlasmicHomepage_logoContainer__3vzZf > .__wab_flex-container > picture > img,
.PlasmicHomepage_logoContainer__3vzZf
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 90px;
  margin-top: 34px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_logoContainer__3vzZf {
    left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_logoContainer__3vzZf > .__wab_flex-container {
    align-content: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_logoContainer__3vzZf > .__wab_flex-container > *,
  .PlasmicHomepage_logoContainer__3vzZf > .__wab_flex-container > .__wab_slot > *,
  .PlasmicHomepage_logoContainer__3vzZf > .__wab_flex-container > picture > img,
  .PlasmicHomepage_logoContainer__3vzZf
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
  }
}
.PlasmicHomepage_img__bNAuf__4B4wW {
  position: relative;
  object-fit: contain;
  max-width: 100%;
  width: 180px;
  height: auto;
  display: block !important;
  flex-shrink: 0;
}
.PlasmicHomepage_img__bNAuf__4B4wW > picture > img {
  object-fit: contain;
}
.PlasmicHomepage_img__bNAuf__4B4wW > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: contain;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_img__bNAuf__4B4wW {
    width: 100px;
    height: auto;
  }
}
.PlasmicHomepage_img__l6QpB__PqtC8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 180px;
  height: auto;
  display: block !important;
  flex-shrink: 0;
}
.PlasmicHomepage_img__l6QpB__PqtC8 > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_img__l6QpB__PqtC8 > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_img__l6QpB__PqtC8 {
    width: 100px;
    height: auto;
  }
}
.PlasmicHomepage_img__rKoQa__2lhMa {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 157px;
  height: auto;
  display: block !important;
  flex-shrink: 0;
}
.PlasmicHomepage_img__rKoQa__2lhMa > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_img__rKoQa__2lhMa > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_img__rKoQa__2lhMa {
    width: 120px;
    height: auto;
  }
}
.PlasmicHomepage_img__l6Ey__4bC95 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 164.999px;
  height: auto;
  display: block !important;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.PlasmicHomepage_img__l6Ey__4bC95 > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_img__l6Ey__4bC95 > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_img__l6Ey__4bC95 {
    width: 100px;
    height: auto;
    left: auto;
    top: auto;
  }
}
.PlasmicHomepage_section3__133Mu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_section3__133Mu {
    margin-top: auto !important;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding-top: 30px;
    flex-column-gap: 0px;
  }
}
.PlasmicHomepage_twoSideBox__3CpCb {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_twoSideBox__3CpCb {
    margin-top: 0px;
    width: 100%;
  }
}
.PlasmicHomepage_freeBox__dFpUx__3q-e_ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_freeBox__dFpUx__3q-e_ {
    align-self: auto;
    margin-top: 0px;
    display: flex;
  }
}
.PlasmicHomepage_freeBox__gsgIm__xKcjL {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_freeBox__gsgIm__xKcjL {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    flex-column-gap: 0px;
  }
}
.PlasmicHomepage_columns___9LyyH__2MFoQ {
  display: flex;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_columns___9LyyH__2MFoQ > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
.PlasmicHomepage_columns___9LyyH__2MFoQ > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns___9LyyH__2MFoQ {
    width: auto;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-self: auto;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns___9LyyH__2MFoQ > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicHomepage_column__oYsq__2JGm- {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_freeBox__qZoqQ__297NW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_subHeading2__CdKgU {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  left: auto;
  top: auto;
  min-width: 0;
}
.PlasmicHomepage_text___6VWl6__10Nk0 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Black Ops One";
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  text-align: left;
  margin-bottom: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_text___6VWl6__10Nk0 {
    font-size: 30px;
  }
}
.PlasmicHomepage_peraContent__3dm_3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_peraContent__3dm_3 {
    font-size: 18px;
  }
}
.PlasmicHomepage_unorderList___5Pkr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.PlasmicHomepage_listIteam__1cfhI {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam__1cfhI {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam3__3UP79 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam3__3UP79 {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam4__1gZU- {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam4__1gZU- {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam5__MBYtO {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam5__MBYtO {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam6__3Hfs5 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam6__3Hfs5 {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam7__3EJO9 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam7__3EJO9 {
    font-size: 16px;
  }
}
.PlasmicHomepage_playButton__1EqUI.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  margin-top: 26px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_playButton__1EqUI.__wab_instance {
    align-self: center;
  }
}
.PlasmicHomepage_svg__xwdIg__2Lhnh {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicHomepage_text__rlNnk__1m3zO {
  font-family: "Montserrat", sans-serif;
}
.PlasmicHomepage_svg__cnRck__1hhr1 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicHomepage_column___49PUp__pwTus {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_column___49PUp__pwTus {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    flex-column-gap: 0px;
  }
}
.PlasmicHomepage_bannerImage2__3Vxp5 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  max-height: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_bannerImage2__3Vxp5 {
    align-self: center;
  }
}
.PlasmicHomepage_imageMarketing2__3d9zE {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 500px;
  width: 511px;
  flex-grow: 1;
  flex-shrink: 0;
}
.PlasmicHomepage_imageMarketing2__3d9zE > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_imageMarketing2__3d9zE > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_imageMarketing2__3d9zE {
    width: 400px;
    height: auto;
    flex-shrink: 0;
  }
}
.PlasmicHomepage_section4__2k9Pc {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#fc5147e0, #fc5147e0),
    url(/static/media/image3.4258bf76.png) 0% 0% / cover;
  min-width: 0;
  display: none;
  padding: 139px 8px;
}
.PlasmicHomepage_freeBox__mbGrv__bwxEB {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_freeBox__mbGrv__bwxEB {
    width: 100%;
    min-width: 0;
  }
}
.PlasmicHomepage_columns__f8BpB__3EcjX {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_columns__f8BpB__3EcjX > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 4 / 12);
}
.PlasmicHomepage_columns__f8BpB__3EcjX > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 8 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__f8BpB__3EcjX {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__f8BpB__3EcjX > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicHomepage_column___98FV__2Lkwx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_img__ivmAp__28K4H {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 256px;
  height: 256px;
  box-shadow: 0px 0px 0px 16px #ffffffdb;
  flex-shrink: 0;
  border-radius: 999px;
}
.PlasmicHomepage_img__ivmAp__28K4H > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_img__ivmAp__28K4H > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
.PlasmicHomepage_column___3WIzk__3s9Rh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_freeBox___8TpS__2mdNA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_testimonialText__35yk6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1px;
  font-size: 40px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_testimonialText__35yk6 {
    max-width: 100%;
    font-size: 30px;
  }
}
.PlasmicHomepage_position__5aTNO {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  line-height: 1.5;
  padding-top: 24px;
  min-width: 0;
}
.PlasmicHomepage_section5__2YTRC {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 50px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_section5__2YTRC {
    padding-bottom: 9px;
  }
}
.PlasmicHomepage_twoSidedContent__1dNg0 {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.PlasmicHomepage_subHeading3__1Tqwc {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Black Ops One";
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 100%;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_subHeading3__1Tqwc {
    text-align: center;
  }
}
.PlasmicHomepage_line2__x_abZ {
  background: rgba(0, 0, 0, 1);
  width: 104px;
  height: 2px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 30px;
  flex-shrink: 0;
}
.PlasmicHomepage_columns__kZfnA__KJmXX {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  left: auto;
  top: auto;
  max-width: 1300px;
  min-width: 0;
  padding: 38px 8px 50px;
}
.PlasmicHomepage_columns__kZfnA__KJmXX > .__wab_flex-container {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.PlasmicHomepage_columns__kZfnA__KJmXX > .__wab_flex-container > :nth-child(2n + 1) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.PlasmicHomepage_columns__kZfnA__KJmXX > .__wab_flex-container > :nth-child(2n + 2) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.PlasmicHomepage_columns__kZfnA__KJmXX > .__wab_flex-container > *,
.PlasmicHomepage_columns__kZfnA__KJmXX > .__wab_flex-container > .__wab_slot > *,
.PlasmicHomepage_columns__kZfnA__KJmXX > .__wab_flex-container > picture > img,
.PlasmicHomepage_columns__kZfnA__KJmXX
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__kZfnA__KJmXX {
    width: 100%;
    height: auto;
    flex-direction: row;
    left: auto;
    top: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__kZfnA__KJmXX > .__wab_flex-container {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__kZfnA__KJmXX > .__wab_flex-container > :nth-child(1n + 1) {
    width: calc((100% - 1 * 16px) * 12 / 12);
  }
}
.PlasmicHomepage_column__hu1Ko__bX4Y4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_h2__lPnqz__S5Q88 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  padding-left: 0px;
  min-width: 0;
}
.PlasmicHomepage_ol__ZoHWm {
  display: flex;
  flex-direction: column;
}
.PlasmicHomepage_p__vLVg8__2n02k {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicHomepage_column__ljdfR__mRL8Q {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_h2__fQi8T__1_oi4 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  padding-top: 0px;
  min-width: 0;
}
.PlasmicHomepage_p___32Moj__iPthf {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicHomepage_column__sr4Hq__2dtWH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_h2__srzcq__2NSos {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.PlasmicHomepage_p__voM7V__29C88 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicHomepage_column__dZO7__3A4rA {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_h2__dSmkB__209Yq {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.PlasmicHomepage_p__n48EA__1M1st {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicHomepage_column__wjDJ__3ubgF {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_h2___4Xecy__2pjqG {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.PlasmicHomepage_p__qWrUo__3mhTP {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicHomepage_column___26Elz__2HgXt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_h2___8MlV2__2gTBw {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.PlasmicHomepage_p___7K1S7__3nxHY {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicHomepage_section6__21jSu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_twoSideBox2__A5unD {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.PlasmicHomepage_columns__ihQh__BtlO5 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_columns__ihQh__BtlO5 > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
.PlasmicHomepage_columns__ihQh__BtlO5 > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__ihQh__BtlO5 {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__ihQh__BtlO5 > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicHomepage_column__l4Dt__2bmoi {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_freeBox__vUd1Y__27hzI {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_subHeading4__Cjl8q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  left: auto;
  top: auto;
  min-width: 0;
}
.PlasmicHomepage_text___8GiWq__2AbJj {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Black Ops One";
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  text-align: left;
  margin-bottom: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_text___8GiWq__2AbJj {
    font-size: 30px;
  }
}
.PlasmicHomepage_peraContent2__27LOM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_peraContent2__27LOM {
    font-size: 18px;
  }
}
.PlasmicHomepage_unorderList2__3E2vT {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  min-width: 0;
}
.PlasmicHomepage_listIteam2__1mnm- {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam2__1mnm- {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam9__3vxzr {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam9__3vxzr {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam10__tJSyh {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam10__tJSyh {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam11__2Ssfr {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam11__2Ssfr {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam12__2yrUX {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam12__2yrUX {
    font-size: 16px;
  }
}
.PlasmicHomepage_listIteam13__tvhug {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_listIteam13__tvhug {
    font-size: 16px;
  }
}
.PlasmicHomepage_playNowButton__2zF__.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  margin-top: 26px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_playNowButton__2zF__.__wab_instance {
    align-self: center;
  }
}
.PlasmicHomepage_svg__zgxPq__aI647 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicHomepage_svg___3FRl0__3lIkV {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicHomepage_column__dCvDz__1zEpe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_bannerImage3__3Nf54 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  max-height: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_bannerImage3__3Nf54 {
    align-self: center;
  }
}
.PlasmicHomepage_imageMarketing3__2g0EC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.PlasmicHomepage_imageMarketing3__2g0EC > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_imageMarketing3__2g0EC > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_imageMarketing3__2g0EC {
    width: 400px;
    height: auto;
    flex-shrink: 0;
  }
}
.PlasmicHomepage_section7__2LCwO {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#2f4461cc, #2f4461cc),
    url(/static/media/image6.c27972ed.png) left 0% top 50% / cover;
  min-width: 0;
  display: none;
  padding: 168px 8px;
}
.PlasmicHomepage_freeBox__l35Lu__28rja {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.PlasmicHomepage_subHeading5__20b-4 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 100%;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_subHeading5__20b-4 {
    font-size: 30px;
    text-align: center;
  }
}
.PlasmicHomepage_line3__2wbIU {
  background: #ffffff;
  width: 104px;
  height: 2px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 30px;
  flex-shrink: 0;
}
.PlasmicHomepage_columns__ebkQe__2W2vy {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 1300px;
  min-width: 0;
  padding: 30px 8px;
}
.PlasmicHomepage_columns__ebkQe__2W2vy > .__wab_flex-container {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.PlasmicHomepage_columns__ebkQe__2W2vy > .__wab_flex-container > :nth-child(2n + 1) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.PlasmicHomepage_columns__ebkQe__2W2vy > .__wab_flex-container > :nth-child(2n + 2) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.PlasmicHomepage_columns__ebkQe__2W2vy > .__wab_flex-container > *,
.PlasmicHomepage_columns__ebkQe__2W2vy > .__wab_flex-container > .__wab_slot > *,
.PlasmicHomepage_columns__ebkQe__2W2vy > .__wab_flex-container > picture > img,
.PlasmicHomepage_columns__ebkQe__2W2vy
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__ebkQe__2W2vy {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__ebkQe__2W2vy > .__wab_flex-container {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__ebkQe__2W2vy > .__wab_flex-container > :nth-child(1n + 1) {
    width: calc((100% - 1 * 16px) * 12 / 12);
  }
}
.PlasmicHomepage_column__dWfi__jV3FE {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_column__dWfi__jV3FE {
    padding-top: 0px;
    justify-content: flex-start;
  }
}
.PlasmicHomepage_blockChapterContent__29wx9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_blockChapterContent__29wx9 {
    padding-top: 40px;
  }
}
.PlasmicHomepage_text___1Sma6__1oVxD {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_text___1Sma6__1oVxD {
    font-size: 18px;
  }
}
.PlasmicHomepage_li___2GwhW__13ZIH {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  padding-top: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li___2GwhW__13ZIH {
    font-size: 16px;
  }
}
.PlasmicHomepage_li__lnuFl__2M5bh {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__lnuFl__2M5bh {
    font-size: 16px;
  }
}
.PlasmicHomepage_li__opBzL__3h2lz {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__opBzL__3h2lz {
    font-size: 16px;
  }
}
.PlasmicHomepage_li__wjLXi__2NHIt {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__wjLXi__2NHIt {
    font-size: 16px;
  }
}
.PlasmicHomepage_column__y9BbI__3pqRw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_column__y9BbI__3pqRw {
    padding-top: 27px;
    justify-content: flex-start;
  }
}
.PlasmicHomepage_blockChapterContent4__NeP40 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
.PlasmicHomepage_text__zeAof__1t4ZA {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_text__zeAof__1t4ZA {
    font-size: 18px;
    padding: 0px 2px 2px;
  }
}
.PlasmicHomepage_li__oJo4K__9dsq7 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  padding-top: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__oJo4K__9dsq7 {
    font-size: 16px;
  }
}
.PlasmicHomepage_li___4KYXg__3VjmX {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li___4KYXg__3VjmX {
    font-size: 16px;
  }
}
.PlasmicHomepage_li___2DEs9__klVlB {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li___2DEs9__klVlB {
    font-size: 16px;
  }
}
.PlasmicHomepage_li__xwvuP__1CBHn {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__xwvuP__1CBHn {
    font-size: 16px;
  }
}
.PlasmicHomepage_column__c6MBw__3n_8w {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_column__c6MBw__3n_8w {
    justify-content: flex-start;
  }
}
.PlasmicHomepage_text__orXT__18xoy {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_text__orXT__18xoy {
    font-size: 18px;
  }
}
.PlasmicHomepage_li__m7N__b-Dcw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.PlasmicHomepage_li__z8DDh__PUsy- {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__z8DDh__PUsy- {
    font-size: 16px;
  }
}
.PlasmicHomepage_text__wbsPt__3pICB {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  line-height: 2;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_text__wbsPt__3pICB {
    font-size: 16px;
  }
}
.PlasmicHomepage_blockChapterContent2__hkLZ6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.PlasmicHomepage_li__iMg74__ITlgk {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__iMg74__ITlgk {
    font-size: 16px;
  }
}
.PlasmicHomepage_li___4BoSo__2uToS {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li___4BoSo__2uToS {
    font-size: 16px;
  }
}
.PlasmicHomepage_column__fOtW9__2Cwzm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_column__fOtW9__2Cwzm {
    justify-content: flex-start;
  }
}
.PlasmicHomepage_blockChapterContent3__2DJ9e {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
.PlasmicHomepage_text__dxjlr__2Pxdn {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_text__dxjlr__2Pxdn {
    font-size: 20px;
  }
}
.PlasmicHomepage_li__tlZx__2CgjP {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  padding-top: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__tlZx__2CgjP {
    font-size: 16px;
  }
}
.PlasmicHomepage_li__djFQn__3JGxm {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__djFQn__3JGxm {
    font-size: 16px;
  }
}
.PlasmicHomepage_li__bgdDm__2WMvs {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__bgdDm__2WMvs {
    font-size: 16px;
  }
}
.PlasmicHomepage_li__wRimx__21WQB {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_li__wRimx__21WQB {
    font-size: 16px;
  }
}
.PlasmicHomepage_section8__3uG8C {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.PlasmicHomepage_columns__qwq7Y__JvUb8 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 1300px;
  min-width: 0;
  padding: 50px 8px;
}
.PlasmicHomepage_columns__qwq7Y__JvUb8 > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 7 / 12);
}
.PlasmicHomepage_columns__qwq7Y__JvUb8 > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 5 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__qwq7Y__JvUb8 {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__qwq7Y__JvUb8 > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicHomepage_column__yVqEh__1Y_Kq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_avtarContent__2ydjr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 30px 8px 8px;
}
.PlasmicHomepage_h2__ikbmD__2v_P1 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_h2__ikbmD__2v_P1 {
    padding-top: 30px;
    font-size: 30px;
  }
}
.PlasmicHomepage_p__giu9B__XLrhb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_p__giu9B__XLrhb {
    font-size: 18px;
  }
}
.PlasmicHomepage_p__ibvYk__14TEx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_p__ibvYk__14TEx {
    font-size: 16px;
  }
}
.PlasmicHomepage_p__rUe9D__20MQq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-weight: 700;
  margin-top: 11px;
  min-width: 0;
  padding: 11px 0px 28px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_p__rUe9D__20MQq {
    font-size: 16px;
  }
}
.PlasmicHomepage_p__kBtTn__RC8t- {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: var(--token-Ksk4fqeEjc9aLu);
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_p__kBtTn__RC8t- {
    font-size: 16px;
  }
}
.PlasmicHomepage_column__kPvu3__1kRsN {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_avatarImage__3iPLy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
.PlasmicHomepage_img__ol1XC__1GHKu {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  border-radius: 8px;
}
.PlasmicHomepage_img__ol1XC__1GHKu > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_img__ol1XC__1GHKu > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_img__ol1XC__1GHKu {
    height: 100%;
    width: 400px;
    min-height: 0;
  }
}
.PlasmicHomepage_section9__2LR4S {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#fc5147e0, #fc5147e0),
    url(/static/media/image10.a59f1be2.png) 0% 0% / cover;
  min-width: 0;
  display: none;
  padding: 169px 8px;
}
.PlasmicHomepage_freeBox__mOIcO__fhqzm {
  width: 100%;
  max-width: 1300px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.PlasmicHomepage_h2__u2Vs3__1fyUA {
  position: relative;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_h2__u2Vs3__1fyUA {
    font-size: 30px;
    text-align: center;
  }
}
.PlasmicHomepage_button__aJZ20.__wab_instance {
  max-width: 100%;
  position: relative;
  margin-top: 26px;
}
.PlasmicHomepage_svg__h9Wbe__3r31E {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicHomepage_text__ma9A__Ga5-O {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
}
.PlasmicHomepage_svg__Y0xNO {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  color: #ffffff;
}
.PlasmicHomepage_footer__1KBRw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 50px 8px;
  border-bottom: 1px solid #d2d2d2;
}
.PlasmicHomepage_columns__uOaW2__tXRLZ {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 1300px;
  padding: 8px;
}
.PlasmicHomepage_columns__uOaW2__tXRLZ > :nth-child(3n + 1) {
  width: calc((100% - 3 * 0px) * 5 / 12);
}
.PlasmicHomepage_columns__uOaW2__tXRLZ > :nth-child(3n + 2) {
  width: calc((100% - 3 * 0px) * 3 / 12);
}
.PlasmicHomepage_columns__uOaW2__tXRLZ > :nth-child(3n + 3) {
  width: calc((100% - 3 * 0px) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__uOaW2__tXRLZ {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__uOaW2__tXRLZ > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicHomepage_column___1KJiH__26h9y {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_footerLogo__3mcVV {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_logo2__2xnSv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 300px;
}
.PlasmicHomepage_logo2__2xnSv > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_logo2__2xnSv > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
.PlasmicHomepage_column__jf5ZN__2n92s {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_h3__supUm__3JJkZ {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-bottom: 10px;
  padding-top: 10px;
  min-width: 0;
}
.PlasmicHomepage_footerLink__2uyg2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 0;
}
.PlasmicHomepage_column__uZrl3__3SxR_ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_h3__zLkBu__8r8Fw {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-bottom: 10px;
  padding-top: 10px;
  min-width: 0;
}
.PlasmicHomepage_footerLink4__30r20 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 0;
}
.PlasmicHomepage_footerLink5__btYR0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 0;
}
.PlasmicHomepage_freeBox___0Gkl__3bZVG {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1300px;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_columns__k8Dch__3YdQ_ {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_columns__k8Dch__3YdQ_ > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 8 / 12);
}
.PlasmicHomepage_columns__k8Dch__3YdQ_ > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__k8Dch__3YdQ_ {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicHomepage_columns__k8Dch__3YdQ_ > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicHomepage_column__ixSXi__yUvT6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_copyRight__2nOJc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  min-width: 0;
}
.PlasmicHomepage_column___7GHmn__1NKlz {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  display: none;
  padding: 8px;
}
.PlasmicHomepage_socialIcons__18BfE {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_socialIcons__18BfE > .__wab_flex-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.PlasmicHomepage_socialIcons__18BfE > .__wab_flex-container > *,
.PlasmicHomepage_socialIcons__18BfE > .__wab_flex-container > .__wab_slot > *,
.PlasmicHomepage_socialIcons__18BfE > .__wab_flex-container > picture > img,
.PlasmicHomepage_socialIcons__18BfE
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 40px;
}
.PlasmicHomepage_link__e8N1B__3Hsiw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.PlasmicHomepage_twitter__3i1PK {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.PlasmicHomepage_twitter__3i1PK > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_twitter__3i1PK > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
.PlasmicHomepage_link___1WCRw__1IHq6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.PlasmicHomepage_facebook__2_RAf {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.PlasmicHomepage_facebook__2_RAf > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_facebook__2_RAf > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
.PlasmicHomepage_link__m5NWc__1PpH4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.PlasmicHomepage_youtube__1lXSm {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.PlasmicHomepage_youtube__1lXSm > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_youtube__1lXSm > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}
.PlasmicHomepage_link__rmVsk__3QP_n {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.PlasmicHomepage_freeBox__rxgph__1LRw8 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicHomepage_vimeo__1c3Yu {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.PlasmicHomepage_vimeo__1c3Yu > picture > img {
  object-fit: cover;
}
.PlasmicHomepage_vimeo__1c3Yu > .PlasmicHomepage___wab_img-spacer__1rl-b > img {
  object-fit: cover;
}

.PlasmicNavbar_root__2DnTu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1440px;
  border-bottom-color: #eceef1;
  border-bottom-style: solid;
  position: relative;
  min-width: 0;
  padding: 20px 56px 15px;
  border-width: 1px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicNavbar_root__2DnTu {
    justify-content: center;
    align-items: center;
    padding: 6px 14px;
  }
}
.PlasmicNavbar_logoWrap__1s2Zt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 8px;
}
.PlasmicNavbar_logo__1ITW5 {
  position: relative;
  object-fit: cover;
  max-width: 220px;
}
.PlasmicNavbar_logo__1ITW5 > picture > img {
  object-fit: cover;
}
.PlasmicNavbar_logo__1ITW5 > .PlasmicNavbar___wab_img-spacer__3kljC > img {
  object-fit: cover;
}
.PlasmicNavbar_navmenu__2K80_ {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 330px;
  flex-wrap: nowrap;
  align-self: auto;
  align-content: stretch;
  margin-left: 26px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicNavbar_navmenu__2K80_ {
    margin-left: 1px;
    padding-right: 0px;
    width: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    max-width: none;
    height: 100%;
    flex-wrap: nowrap;
    align-content: stretch;
    flex-row-gap: 0px;
    min-height: 0;
  }
}
.PlasmicNavbar_link3__3Um-F {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  position: relative;
  width: auto;
  height: auto;
  align-self: auto;
  left: auto;
  top: auto;
  padding: 0px 15px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicNavbar_link3__3Um-F {
    font-size: 10px;
    left: auto;
    top: auto;
    padding-right: 8px;
    position: relative;
  }
}
.PlasmicNavbar_link2__2u6oh {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  position: relative;
  width: auto;
  height: auto;
  align-self: flex-start;
  left: auto;
  top: auto;
  padding: 0px 15px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicNavbar_link2__2u6oh {
    font-size: 10px;
    left: auto;
    top: auto;
    padding-right: 5px;
    position: relative;
    align-self: auto;
  }
}
.PlasmicNavbar_link4__1G4MQ {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  position: relative;
  width: auto;
  height: auto;
  align-self: flex-start;
  left: auto;
  top: auto;
  padding: 0px 15px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicNavbar_link4__1G4MQ {
    font-size: 10px;
    left: auto;
    top: auto;
    padding-right: 5px;
    position: relative;
    align-self: auto;
  }
}
.PlasmicNavbar_navBarButtonBlock__1bDbt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicNavbar_navBarButtonBlock__1bDbt {
    margin-left: 0px;
  }
}
.PlasmicNavbar_syncWallet__p-Ypw.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PlasmicNavbar_svg__zyhrw__IUowg {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicNavbar_btnText__3XlAM {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicNavbar_btnText__3XlAM {
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.PlasmicNavbar_svg__l6D7U__18PSM {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}

.PlasmicPlay_root__1jiuF {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--token-Jm9Nk06qypQ6X5);
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.PlasmicPlay_root__1jiuF > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.PlasmicPlay_root__1jiuF > .__wab_flex-container > *,
.PlasmicPlay_root__1jiuF > .__wab_flex-container > .__wab_slot > *,
.PlasmicPlay_root__1jiuF > .__wab_flex-container > picture > img,
.PlasmicPlay_root__1jiuF > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 16px;
}
.PlasmicPlay_navbar__kVASW.__wab_instance {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_navbar__kVASW.__wab_instance {
    align-self: auto;
  }
}
.PlasmicPlay_section2__3BpZH {
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  min-width: 0;
  display: none;
}
.PlasmicPlay_section2__3BpZH > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 60px);
  height: calc(100% + 60px);
}
.PlasmicPlay_section2__3BpZH > .__wab_flex-container > *,
.PlasmicPlay_section2__3BpZH > .__wab_flex-container > .__wab_slot > *,
.PlasmicPlay_section2__3BpZH > .__wab_flex-container > picture > img,
.PlasmicPlay_section2__3BpZH
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 60px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_section2__3BpZH {
    width: var(--token-AJG1E7gV1cZl6J);
    align-self: auto;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_section2__3BpZH > .__wab_flex-container {
    min-height: 0;
  }
}
.PlasmicPlay_subHeading__1kEaB {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_subHeading__1kEaB {
    left: 0px;
    text-align: center;
    width: 100%;
    font-size: 30px;
    min-width: 0;
  }
}
.PlasmicPlay_logoContainer__15dg- {
  flex-direction: row;
  width: 1350px;
  max-width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
}
.PlasmicPlay_logoContainer__15dg- > .__wab_flex-container {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-left: calc(0px - 90px);
  width: calc(100% + 90px);
  margin-top: calc(0px - 34px);
  height: calc(100% + 34px);
}
.PlasmicPlay_logoContainer__15dg- > .__wab_flex-container > *,
.PlasmicPlay_logoContainer__15dg- > .__wab_flex-container > .__wab_slot > *,
.PlasmicPlay_logoContainer__15dg- > .__wab_flex-container > picture > img,
.PlasmicPlay_logoContainer__15dg-
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 90px;
  margin-top: 34px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_logoContainer__15dg- {
    left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_logoContainer__15dg- > .__wab_flex-container {
    align-content: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_logoContainer__15dg- > .__wab_flex-container > *,
  .PlasmicPlay_logoContainer__15dg- > .__wab_flex-container > .__wab_slot > *,
  .PlasmicPlay_logoContainer__15dg- > .__wab_flex-container > picture > img,
  .PlasmicPlay_logoContainer__15dg-
    > .__wab_flex-container
    > .__wab_slot
    > picture
    > img {
    margin-left: 0px;
  }
}
.PlasmicPlay_img__o1Ha__3qzQJ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 180px;
  height: auto;
  display: block !important;
  flex-shrink: 0;
}
.PlasmicPlay_img__o1Ha__3qzQJ > picture > img {
  object-fit: cover;
}
.PlasmicPlay_img__o1Ha__3qzQJ > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_img__o1Ha__3qzQJ {
    width: 100px;
    height: auto;
  }
}
.PlasmicPlay_img__dIyF__1lacZ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 180px;
  height: 85px;
  display: block !important;
  flex-shrink: 0;
}
.PlasmicPlay_img__dIyF__1lacZ > picture > img {
  object-fit: cover;
}
.PlasmicPlay_img__dIyF__1lacZ > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_img__dIyF__1lacZ {
    width: 100px;
    height: auto;
  }
}
.PlasmicPlay_img___2O5__Y2-88 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 157px;
  height: 66px;
  display: block !important;
  flex-shrink: 0;
}
.PlasmicPlay_img___2O5__Y2-88 > picture > img {
  object-fit: cover;
}
.PlasmicPlay_img___2O5__Y2-88 > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_img___2O5__Y2-88 {
    width: 120px;
    height: auto;
  }
}
.PlasmicPlay_img__jgrVp__2ZMZf {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 164.999px;
  height: 80.999px;
  display: block !important;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.PlasmicPlay_img__jgrVp__2ZMZf > picture > img {
  object-fit: cover;
}
.PlasmicPlay_img__jgrVp__2ZMZf > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_img__jgrVp__2ZMZf {
    width: 100px;
    height: auto;
    left: auto;
    top: auto;
  }
}
.PlasmicPlay_section3__1fjwN {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_section3__1fjwN {
    margin-top: auto !important;
    flex-direction: column;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding-top: 30px;
    flex-column-gap: 0px;
  }
}
.PlasmicPlay_twoSideBox__3nhJm {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_twoSideBox__3nhJm {
    margin-top: 0px;
    width: 100%;
  }
}
.PlasmicPlay_freeBox__n93WH__1lqlT {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_freeBox__n93WH__1lqlT {
    align-self: auto;
    margin-top: 0px;
    display: flex;
  }
}
.PlasmicPlay_freeBox__buMRa__3TSL5 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_freeBox__buMRa__3TSL5 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    flex-column-gap: 0px;
  }
}
.PlasmicPlay_columns__vYf4V__tBU2_ {
  display: flex;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_columns__vYf4V__tBU2_ > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
.PlasmicPlay_columns__vYf4V__tBU2_ > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__vYf4V__tBU2_ {
    width: auto;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-self: auto;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__vYf4V__tBU2_ > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicPlay_column__xqsYd__1Jiv_ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_freeBox__jnRaU__375Qc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_subHeading2__2SMgL {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  left: auto;
  top: auto;
  min-width: 0;
}
.PlasmicPlay_text__pvDw6__f85KX {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Ultra", serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  text-align: left;
  margin-bottom: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_text__pvDw6__f85KX {
    font-size: 30px;
  }
}
.PlasmicPlay_peraContent__3LynC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_peraContent__3LynC {
    font-size: 18px;
  }
}
.PlasmicPlay_unorderList__35NYc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.PlasmicPlay_listIteam__Zk-dg {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam__Zk-dg {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam3__9Ivfu {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam3__9Ivfu {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam4__1hAaj {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam4__1hAaj {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam5__2IeeG {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam5__2IeeG {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam6__3ntZP {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam6__3ntZP {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam7__3BF1l {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam7__3BF1l {
    font-size: 16px;
  }
}
.PlasmicPlay_button__ujX__2_Hp-.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  margin-top: 26px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_button__ujX__2_Hp-.__wab_instance {
    align-self: center;
  }
}
.PlasmicPlay_svg__fxUzG__3JTc8 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPlay_text__xI7Uo__1Yand {
  font-family: "Montserrat", sans-serif;
}
.PlasmicPlay_svg__gwPYy__2tcq3 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPlay_column__w8Aga__1dE01 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_column__w8Aga__1dE01 {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    flex-column-gap: 0px;
  }
}
.PlasmicPlay_bannerImage2__mSDSA {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  max-height: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_bannerImage2__mSDSA {
    align-self: center;
  }
}
.PlasmicPlay_imageMarketing2__IlOQv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.PlasmicPlay_imageMarketing2__IlOQv > picture > img {
  object-fit: cover;
}
.PlasmicPlay_imageMarketing2__IlOQv > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_imageMarketing2__IlOQv {
    width: 400px;
    height: auto;
    flex-shrink: 0;
  }
}
.PlasmicPlay_section4__32Zoe {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#fc5147e0, #fc5147e0),
    url(/static/media/image3.4258bf76.png) 0% 0% / cover;
  min-width: 0;
  display: none;
  padding: 139px 8px;
}
.PlasmicPlay_freeBox__h1S0__3maro {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_freeBox__h1S0__3maro {
    width: 100%;
    min-width: 0;
  }
}
.PlasmicPlay_columns__zEhE__2TLvS {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_columns__zEhE__2TLvS > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 4 / 12);
}
.PlasmicPlay_columns__zEhE__2TLvS > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 8 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__zEhE__2TLvS {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__zEhE__2TLvS > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicPlay_column__fX6Ss__3f72u {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_img__v9QWz__3igoh {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 256px;
  height: 256px;
  box-shadow: 0px 0px 0px 16px #ffffffdb;
  flex-shrink: 0;
  border-radius: 999px;
}
.PlasmicPlay_img__v9QWz__3igoh > picture > img {
  object-fit: cover;
}
.PlasmicPlay_img__v9QWz__3igoh > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
.PlasmicPlay_column__qtnpR__1pfOy {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_freeBox__wtjl__3_GOO {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_testimonialText__1mnjK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1px;
  font-size: 40px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_testimonialText__1mnjK {
    max-width: 100%;
    font-size: 30px;
  }
}
.PlasmicPlay_position__15b07 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  line-height: 1.5;
  padding-top: 24px;
  min-width: 0;
}
.PlasmicPlay_section5__1_4PB {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 50px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_section5__1_4PB {
    padding-bottom: 9px;
  }
}
.PlasmicPlay_twoSidedContent__3VKJy {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.PlasmicPlay_subHeading3__4MYqO {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 100%;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_subHeading3__4MYqO {
    text-align: center;
  }
}
.PlasmicPlay_line2__32Hpm {
  background: rgba(0, 0, 0, 1);
  width: 104px;
  height: 2px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 30px;
  flex-shrink: 0;
}
.PlasmicPlay_columns__xug8A__2Pgcm {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  left: auto;
  top: auto;
  max-width: 1300px;
  min-width: 0;
  padding: 38px 8px 50px;
}
.PlasmicPlay_columns__xug8A__2Pgcm > .__wab_flex-container {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.PlasmicPlay_columns__xug8A__2Pgcm > .__wab_flex-container > :nth-child(2n + 1) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.PlasmicPlay_columns__xug8A__2Pgcm > .__wab_flex-container > :nth-child(2n + 2) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.PlasmicPlay_columns__xug8A__2Pgcm > .__wab_flex-container > *,
.PlasmicPlay_columns__xug8A__2Pgcm > .__wab_flex-container > .__wab_slot > *,
.PlasmicPlay_columns__xug8A__2Pgcm > .__wab_flex-container > picture > img,
.PlasmicPlay_columns__xug8A__2Pgcm
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__xug8A__2Pgcm {
    width: 100%;
    height: auto;
    flex-direction: row;
    left: auto;
    top: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__xug8A__2Pgcm > .__wab_flex-container {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__xug8A__2Pgcm > .__wab_flex-container > :nth-child(1n + 1) {
    width: calc((100% - 1 * 16px) * 12 / 12);
  }
}
.PlasmicPlay_column__yokCi__2rszx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_h2__dkKct__3fEGU {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  padding-left: 0px;
  min-width: 0;
}
.PlasmicPlay_ol__nupW1 {
  display: flex;
  flex-direction: column;
}
.PlasmicPlay_p__rd8Fe___awft {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicPlay_column__xbRGv__2Bg-3 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_h2___06CCc__1ZUQ- {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.PlasmicPlay_p__jHPem__eu_IW {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicPlay_column__cygm__1nqyE {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_h2__c7AYk__3q87h {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.PlasmicPlay_p__luTYs__1ksJI {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicPlay_column__rSRc__3RZMQ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_h2__e57No__2VY7I {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.PlasmicPlay_p__toMRn__3jQqO {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicPlay_column__lM9RJ__2NbrT {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_h2__ev7C__6I7sl {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.PlasmicPlay_p__tvy4N__32Siy {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicPlay_column___16N8A__2YkHP {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_h2__gNfaS__IrXkL {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.PlasmicPlay_p__eJhkr__25NJD {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.PlasmicPlay_section6__3JWEp {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  display: none;
  padding: 8px;
}
.PlasmicPlay_twoSideBox2__2WkFR {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.PlasmicPlay_columns__e7Ri6__3WZCV {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_columns__e7Ri6__3WZCV > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
.PlasmicPlay_columns__e7Ri6__3WZCV > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__e7Ri6__3WZCV {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__e7Ri6__3WZCV > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicPlay_column__z6NYo__3nB90 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_freeBox__rlKz__2jAQK {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_subHeading4__13RzI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  left: auto;
  top: auto;
  min-width: 0;
}
.PlasmicPlay_text__kqrBx__2-pDP {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  text-align: left;
  margin-bottom: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_text__kqrBx__2-pDP {
    font-size: 30px;
  }
}
.PlasmicPlay_peraContent2__2M7m0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_peraContent2__2M7m0 {
    font-size: 18px;
  }
}
.PlasmicPlay_unorderList2__1QqZa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  min-width: 0;
}
.PlasmicPlay_listIteam2__3hKGa {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam2__3hKGa {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam9__3BPQA {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam9__3BPQA {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam10__bM22w {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam10__bM22w {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam11__1WB3t {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam11__1WB3t {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam12__2EuYO {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam12__2EuYO {
    font-size: 16px;
  }
}
.PlasmicPlay_listIteam13__2DoHw {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_listIteam13__2DoHw {
    font-size: 16px;
  }
}
.PlasmicPlay_button__wjSZr__32M15.__wab_instance {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  margin-top: 26px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_button__wjSZr__32M15.__wab_instance {
    align-self: center;
  }
}
.PlasmicPlay_svg__e2XHu__1rwRi {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPlay_svg__c73F__rdXyI {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPlay_column__gHRr__1rR-d {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_bannerImage3__1XexE {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  max-height: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_bannerImage3__1XexE {
    align-self: center;
  }
}
.PlasmicPlay_imageMarketing3__1Abfq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.PlasmicPlay_imageMarketing3__1Abfq > picture > img {
  object-fit: cover;
}
.PlasmicPlay_imageMarketing3__1Abfq > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_imageMarketing3__1Abfq {
    width: 400px;
    height: auto;
    flex-shrink: 0;
  }
}
.PlasmicPlay_section7__2WPv6 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#2f4461cc, #2f4461cc),
    url(/static/media/image6.c27972ed.png) left 0% top 50% / cover;
  min-width: 0;
  display: none;
  padding: 168px 8px;
}
.PlasmicPlay_freeBox__eXdkf__1sOPv {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.PlasmicPlay_subHeading5__2HY09 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 100%;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_subHeading5__2HY09 {
    font-size: 30px;
    text-align: center;
  }
}
.PlasmicPlay_line3__3cSt9 {
  background: #ffffff;
  width: 104px;
  height: 2px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 30px;
  flex-shrink: 0;
}
.PlasmicPlay_columns___7MYmg__2RUe4 {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 1300px;
  min-width: 0;
  padding: 30px 8px;
}
.PlasmicPlay_columns___7MYmg__2RUe4 > .__wab_flex-container {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.PlasmicPlay_columns___7MYmg__2RUe4 > .__wab_flex-container > :nth-child(2n + 1) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.PlasmicPlay_columns___7MYmg__2RUe4 > .__wab_flex-container > :nth-child(2n + 2) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.PlasmicPlay_columns___7MYmg__2RUe4 > .__wab_flex-container > *,
.PlasmicPlay_columns___7MYmg__2RUe4 > .__wab_flex-container > .__wab_slot > *,
.PlasmicPlay_columns___7MYmg__2RUe4 > .__wab_flex-container > picture > img,
.PlasmicPlay_columns___7MYmg__2RUe4
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns___7MYmg__2RUe4 {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns___7MYmg__2RUe4 > .__wab_flex-container {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns___7MYmg__2RUe4 > .__wab_flex-container > :nth-child(1n + 1) {
    width: calc((100% - 1 * 16px) * 12 / 12);
  }
}
.PlasmicPlay_column__s0Kvw__3SuKt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_column__s0Kvw__3SuKt {
    padding-top: 0px;
    justify-content: flex-start;
  }
}
.PlasmicPlay_blockChapterContent__1nU3a {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_blockChapterContent__1nU3a {
    padding-top: 40px;
  }
}
.PlasmicPlay_text__cKmDk__UIvi1 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_text__cKmDk__UIvi1 {
    font-size: 18px;
  }
}
.PlasmicPlay_li__hvcIp__2gQGX {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  padding-top: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__hvcIp__2gQGX {
    font-size: 16px;
  }
}
.PlasmicPlay_li__e5Ar__1w42E {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__e5Ar__1w42E {
    font-size: 16px;
  }
}
.PlasmicPlay_li__wlvIg__GeHXi {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__wlvIg__GeHXi {
    font-size: 16px;
  }
}
.PlasmicPlay_li__g2RWf__2_Dk9 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__g2RWf__2_Dk9 {
    font-size: 16px;
  }
}
.PlasmicPlay_column__d7F1__34WN7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_column__d7F1__34WN7 {
    padding-top: 27px;
    justify-content: flex-start;
  }
}
.PlasmicPlay_blockChapterContent4__2SZAx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
.PlasmicPlay_text___1TqXm__1tJAg {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_text___1TqXm__1tJAg {
    font-size: 18px;
    padding: 0px 2px 2px;
  }
}
.PlasmicPlay_li__oktnG__1C1Pt {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  padding-top: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__oktnG__1C1Pt {
    font-size: 16px;
  }
}
.PlasmicPlay_li___6JjN__1Tm19 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li___6JjN__1Tm19 {
    font-size: 16px;
  }
}
.PlasmicPlay_li__xpAjz__1u2eF {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__xpAjz__1u2eF {
    font-size: 16px;
  }
}
.PlasmicPlay_li__z2Ty2__1nwfD {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__z2Ty2__1nwfD {
    font-size: 16px;
  }
}
.PlasmicPlay_column__s3OMt__1a8GT {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_column__s3OMt__1a8GT {
    justify-content: flex-start;
  }
}
.PlasmicPlay_text__kT30P__3NPsI {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_text__kT30P__3NPsI {
    font-size: 18px;
  }
}
.PlasmicPlay_li__h1Uon__B9yUc {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.PlasmicPlay_li__efUo__2rEBR {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__efUo__2rEBR {
    font-size: 16px;
  }
}
.PlasmicPlay_text___8DJjf__Wtb-w {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  line-height: 2;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_text___8DJjf__Wtb-w {
    font-size: 16px;
  }
}
.PlasmicPlay_blockChapterContent2__2QDhT {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.PlasmicPlay_li__iEzQ__2C-I2 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__iEzQ__2C-I2 {
    font-size: 16px;
  }
}
.PlasmicPlay_li__jr55T__1qT4y {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__jr55T__1qT4y {
    font-size: 16px;
  }
}
.PlasmicPlay_column__aUqaC__3sPrZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_column__aUqaC__3sPrZ {
    justify-content: flex-start;
  }
}
.PlasmicPlay_blockChapterContent3__26a6L {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
.PlasmicPlay_text__zcJsz__11Dnq {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_text__zcJsz__11Dnq {
    font-size: 20px;
  }
}
.PlasmicPlay_li__yqco3__1Sm_u {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  padding-top: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__yqco3__1Sm_u {
    font-size: 16px;
  }
}
.PlasmicPlay_li___4Cadn__1sHPW {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li___4Cadn__1sHPW {
    font-size: 16px;
  }
}
.PlasmicPlay_li__owHHk__3fkAh {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__owHHk__3fkAh {
    font-size: 16px;
  }
}
.PlasmicPlay_li__lJUvp__3AGh- {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_li__lJUvp__3AGh- {
    font-size: 16px;
  }
}
.PlasmicPlay_section8__Z2zpS {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.PlasmicPlay_columns__o2INc__PfOYR {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 1300px;
  min-width: 0;
  padding: 50px 8px;
}
.PlasmicPlay_columns__o2INc__PfOYR > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 7 / 12);
}
.PlasmicPlay_columns__o2INc__PfOYR > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 5 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__o2INc__PfOYR {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__o2INc__PfOYR > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicPlay_column___4Umgm__3ms_D {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_avtarContent__3uamB {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 30px 8px 8px;
}
.PlasmicPlay_h2___4L7NH__1x_o5 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_h2___4L7NH__1x_o5 {
    padding-top: 30px;
    font-size: 30px;
  }
}
.PlasmicPlay_p__tx8Cy__3ZROr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_p__tx8Cy__3ZROr {
    font-size: 18px;
  }
}
.PlasmicPlay_p__q0IHn__3ocYx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_p__q0IHn__3ocYx {
    font-size: 16px;
  }
}
.PlasmicPlay_p__t60DY___fEh1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-weight: 700;
  margin-top: 11px;
  min-width: 0;
  padding: 11px 0px 28px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_p__t60DY___fEh1 {
    font-size: 16px;
  }
}
.PlasmicPlay_p__a4XJ__1_2DH {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: var(--token-Ksk4fqeEjc9aLu);
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_p__a4XJ__1_2DH {
    font-size: 16px;
  }
}
.PlasmicPlay_column__aCxlt__-LYOC {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_avatarImage__ykQqf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
.PlasmicPlay_img___4UQfM__1Hhij {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  border-radius: 8px;
}
.PlasmicPlay_img___4UQfM__1Hhij > picture > img {
  object-fit: cover;
}
.PlasmicPlay_img___4UQfM__1Hhij > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_img___4UQfM__1Hhij {
    height: 100%;
    width: 400px;
    min-height: 0;
  }
}
.PlasmicPlay_section9__2DB01 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#fc5147e0, #fc5147e0),
    url(/static/media/image10.a59f1be2.png) 0% 0% / cover;
  min-width: 0;
  display: none;
  padding: 169px 8px;
}
.PlasmicPlay_freeBox__wHvrU__36CJA {
  width: 100%;
  max-width: 1300px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.PlasmicPlay_h2__pAd6X__qHzDq {
  position: relative;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_h2__pAd6X__qHzDq {
    font-size: 30px;
    text-align: center;
  }
}
.PlasmicPlay_button__ktXSw__3QmJl.__wab_instance {
  max-width: 100%;
  position: relative;
  margin-top: 26px;
}
.PlasmicPlay_svg__bfzfc__3lDDm {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPlay_text__uUmLb__5-Seu {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
}
.PlasmicPlay_svg__3uLH8 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  color: #ffffff;
}
.PlasmicPlay_section1__Tcjfe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 80px;
  align-self: flex-end;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_section1__Tcjfe {
    padding-top: 70px;
  }
}
.PlasmicPlay_container__2_AjI {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_container__2_AjI {
    width: auto;
  }
}
.PlasmicPlay_freeBox__vPp9__XuLQX {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_freeBox__vPp9__XuLQX > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 105px);
  width: calc(100% + 105px);
}
.PlasmicPlay_freeBox__vPp9__XuLQX > .__wab_flex-container > *,
.PlasmicPlay_freeBox__vPp9__XuLQX > .__wab_flex-container > .__wab_slot > *,
.PlasmicPlay_freeBox__vPp9__XuLQX > .__wab_flex-container > picture > img,
.PlasmicPlay_freeBox__vPp9__XuLQX
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 105px;
}
.PlasmicPlay_freeBox__ggTSo__15Phz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_img__klbWk__3oxz5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 4px 8px 24px 0px #00000080;
  display: none !important;
}
.PlasmicPlay_img__klbWk__3oxz5 > picture > img {
  object-fit: cover;
}
.PlasmicPlay_img__klbWk__3oxz5 > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
.PlasmicPlay_useTicket__2W4JA.__wab_instance {
  max-width: 100%;
  position: relative;
  margin-top: 49px;
  display: none;
}
.PlasmicPlay_svg___3EUgU__JSWLP {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPlay_svg__msW0__1i_Vw {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPlay_img__tiDjR__1rdtC {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.PlasmicPlay_img__tiDjR__1rdtC > picture > img {
  object-fit: cover;
}
.PlasmicPlay_img__tiDjR__1rdtC > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
.PlasmicPlay_flexBox__1JxGQ {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  flex-shrink: 1;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_flexBox__1JxGQ > .__wab_flex-container {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.PlasmicPlay_flexBox__1JxGQ > .__wab_flex-container > :nth-child(2n + 1) {
  width: calc((100% - 2 * 16px) * 5 / 12);
}
.PlasmicPlay_flexBox__1JxGQ > .__wab_flex-container > :nth-child(2n + 2) {
  width: calc((100% - 2 * 16px) * 7 / 12);
}
.PlasmicPlay_flexBox__1JxGQ > .__wab_flex-container > *,
.PlasmicPlay_flexBox__1JxGQ > .__wab_flex-container > .__wab_slot > *,
.PlasmicPlay_flexBox__1JxGQ > .__wab_flex-container > picture > img,
.PlasmicPlay_flexBox__1JxGQ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_flexBox__1JxGQ {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_flexBox__1JxGQ > .__wab_flex-container {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_flexBox__1JxGQ > .__wab_flex-container > :nth-child(1n + 1) {
    width: calc((100% - 1 * 16px) * 12 / 12);
  }
}
.PlasmicPlay_column__skL05__8ECIk {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_bannerImage__tsG21 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  max-height: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_bannerImage__tsG21 {
    align-self: center;
  }
}
.PlasmicPlay_img___8MQvO__3j7LA {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 4px 8px 24px 0px #00000080;
  width: 400px;
  flex-shrink: 0;
}
.PlasmicPlay_img___8MQvO__3j7LA > picture > img {
  object-fit: cover;
}
.PlasmicPlay_img___8MQvO__3j7LA > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
.PlasmicPlay_column__vzi9A__1q7ZT {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_contentBlock__3suwI {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px 8px 8px;
}
.PlasmicPlay_mainHeading__7rTY7 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 40px;
  font-weight: 700;
  font-family: "Black Ops One";
  line-height: 1.2;
  letter-spacing: 0px;
  position: relative;
  height: auto;
  max-width: none;
  padding-top: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_mainHeading__7rTY7 {
    font-size: 46px;
  }
}
.PlasmicPlay_peraBanner__2IV7s {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.PlasmicPlay_text__rzWs4__4gDal {
  position: relative;
  font-family: "Special Elite";
  font-size: 24px;
  font-weight: 500;
  color: var(--token-Ksk4fqeEjc9aLu);
  letter-spacing: 1px;
}
.PlasmicPlay_freeBox__eSNzB__2hp9- {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  min-width: 0;
  padding: 0px;
  margin: 20px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_freeBox__eSNzB__2hp9- {
    top: auto;
    left: auto;
    align-self: center;
  }
}
.PlasmicPlay_button__mOx6O__1iKWG.__wab_instance {
  max-width: 100%;
}
.PlasmicPlay_svg__dtKH__98EiC {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPlay_text__xl1Hv__3_iU8 {
  height: auto;
}
.PlasmicPlay_svg__ugF7I__2bppi {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPlay_footer__dT6bz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 50px 8px;
  border-bottom: 1px solid #d2d2d2;
}
.PlasmicPlay_columns___2RtIb__htVgf {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 1300px;
  padding: 8px;
}
.PlasmicPlay_columns___2RtIb__htVgf > :nth-child(3n + 1) {
  width: calc((100% - 3 * 0px) * 5 / 12);
}
.PlasmicPlay_columns___2RtIb__htVgf > :nth-child(3n + 2) {
  width: calc((100% - 3 * 0px) * 3 / 12);
}
.PlasmicPlay_columns___2RtIb__htVgf > :nth-child(3n + 3) {
  width: calc((100% - 3 * 0px) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns___2RtIb__htVgf {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns___2RtIb__htVgf > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicPlay_column__qux7W__2q4z6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_footerLogo__12aUz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_logo2__22ZFr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 300px;
}
.PlasmicPlay_logo2__22ZFr > picture > img {
  object-fit: cover;
}
.PlasmicPlay_logo2__22ZFr > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
.PlasmicPlay_column___0GDq1__1sNda {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_h3__tyZz__1QILJ {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-bottom: 10px;
  padding-top: 10px;
  min-width: 0;
}
.PlasmicPlay_footerLink__1jDmQ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 0;
}
.PlasmicPlay_column__coKz3__3HASw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_h3__mgbbt__2ii4f {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-bottom: 10px;
  padding-top: 10px;
  min-width: 0;
}
.PlasmicPlay_footerLink4__TOtWh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 0;
}
.PlasmicPlay_footerLink5__1oGUS {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 0;
}
.PlasmicPlay_freeBox__nxFqw__3JgB- {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1300px;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_columns__b4EJn__WNoHf {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_columns__b4EJn__WNoHf > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 8 / 12);
}
.PlasmicPlay_columns__b4EJn__WNoHf > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__b4EJn__WNoHf {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPlay_columns__b4EJn__WNoHf > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.PlasmicPlay_column__ygnOd__41oMQ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_copyRight__178kC {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  min-width: 0;
}
.PlasmicPlay_column__oaT6L__2B4xR {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  display: none;
  padding: 8px;
}
.PlasmicPlay_socialIcons__1eqqj {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_socialIcons__1eqqj > .__wab_flex-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.PlasmicPlay_socialIcons__1eqqj > .__wab_flex-container > *,
.PlasmicPlay_socialIcons__1eqqj > .__wab_flex-container > .__wab_slot > *,
.PlasmicPlay_socialIcons__1eqqj > .__wab_flex-container > picture > img,
.PlasmicPlay_socialIcons__1eqqj
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 40px;
}
.PlasmicPlay_link__nzHno__15dyw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.PlasmicPlay_twitter__aLVz9 {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.PlasmicPlay_twitter__aLVz9 > picture > img {
  object-fit: cover;
}
.PlasmicPlay_twitter__aLVz9 > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
.PlasmicPlay_link__ngWn5__3osYL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.PlasmicPlay_facebook__3sbKN {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.PlasmicPlay_facebook__3sbKN > picture > img {
  object-fit: cover;
}
.PlasmicPlay_facebook__3sbKN > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
.PlasmicPlay_link__ryJy5__2TCm0 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.PlasmicPlay_youtube__2Xclj {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.PlasmicPlay_youtube__2Xclj > picture > img {
  object-fit: cover;
}
.PlasmicPlay_youtube__2Xclj > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}
.PlasmicPlay_link___9Yn9W__2H5Or {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.PlasmicPlay_freeBox__ntJw__3SNbV {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicPlay_vimeo__sR8hK {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.PlasmicPlay_vimeo__sR8hK > picture > img {
  object-fit: cover;
}
.PlasmicPlay_vimeo__sR8hK > .PlasmicPlay___wab_img-spacer__1jZwo > img {
  object-fit: cover;
}

