.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 1440px;
  border-bottom-color: #eceef1;
  border-bottom-style: solid;
  position: relative;
  min-width: 0;
  padding: 20px 56px 15px;
  border-width: 1px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    justify-content: center;
    align-items: center;
    padding: 6px 14px;
  }
}
.logoWrap {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 8px;
}
.logo {
  position: relative;
  object-fit: cover;
  max-width: 220px;
}
.logo > picture > img {
  object-fit: cover;
}
.logo > .__wab_img-spacer > img {
  object-fit: cover;
}
.navmenu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 330px;
  flex-wrap: nowrap;
  align-self: auto;
  align-content: stretch;
  margin-left: 26px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navmenu {
    margin-left: 1px;
    padding-right: 0px;
    width: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    max-width: none;
    height: 100%;
    flex-wrap: nowrap;
    align-content: stretch;
    flex-row-gap: 0px;
    min-height: 0;
  }
}
.link3 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  position: relative;
  width: auto;
  height: auto;
  align-self: auto;
  left: auto;
  top: auto;
  padding: 0px 15px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link3 {
    font-size: 10px;
    left: auto;
    top: auto;
    padding-right: 8px;
    position: relative;
  }
}
.link2 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  position: relative;
  width: auto;
  height: auto;
  align-self: flex-start;
  left: auto;
  top: auto;
  padding: 0px 15px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link2 {
    font-size: 10px;
    left: auto;
    top: auto;
    padding-right: 5px;
    position: relative;
    align-self: auto;
  }
}
.link4 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  position: relative;
  width: auto;
  height: auto;
  align-self: flex-start;
  left: auto;
  top: auto;
  padding: 0px 15px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link4 {
    font-size: 10px;
    left: auto;
    top: auto;
    padding-right: 5px;
    position: relative;
    align-self: auto;
  }
}
.navBarButtonBlock {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navBarButtonBlock {
    margin-left: 0px;
  }
}
.syncWallet:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__zyhrw {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.btnText {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .btnText {
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.svg__l6D7U {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
