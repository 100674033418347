.root {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--token-Jm9Nk06qypQ6X5);
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.navbar:global(.__wab_instance) {
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navbar:global(.__wab_instance) {
    align-self: auto;
  }
}
.section2 {
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  min-width: 0;
  display: none;
}
.section2 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 60px);
  height: calc(100% + 60px);
}
.section2 > :global(.__wab_flex-container) > *,
.section2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.section2 > :global(.__wab_flex-container) > picture > img,
.section2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 60px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .section2 {
    width: var(--token-AJG1E7gV1cZl6J);
    align-self: auto;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .section2 > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
.subHeading {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .subHeading {
    left: 0px;
    text-align: center;
    width: 100%;
    font-size: 30px;
    min-width: 0;
  }
}
.logoContainer {
  flex-direction: row;
  width: 1350px;
  max-width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
}
.logoContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-left: calc(0px - 90px);
  width: calc(100% + 90px);
  margin-top: calc(0px - 34px);
  height: calc(100% + 34px);
}
.logoContainer > :global(.__wab_flex-container) > *,
.logoContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.logoContainer > :global(.__wab_flex-container) > picture > img,
.logoContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 90px;
  margin-top: 34px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .logoContainer {
    left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .logoContainer > :global(.__wab_flex-container) {
    align-content: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .logoContainer > :global(.__wab_flex-container) > *,
  .logoContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .logoContainer > :global(.__wab_flex-container) > picture > img,
  .logoContainer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.img__o1Ha {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 180px;
  height: auto;
  display: block !important;
  flex-shrink: 0;
}
.img__o1Ha > picture > img {
  object-fit: cover;
}
.img__o1Ha > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__o1Ha {
    width: 100px;
    height: auto;
  }
}
.img__dIyF {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 180px;
  height: 85px;
  display: block !important;
  flex-shrink: 0;
}
.img__dIyF > picture > img {
  object-fit: cover;
}
.img__dIyF > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__dIyF {
    width: 100px;
    height: auto;
  }
}
.img___2O5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 157px;
  height: 66px;
  display: block !important;
  flex-shrink: 0;
}
.img___2O5 > picture > img {
  object-fit: cover;
}
.img___2O5 > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img___2O5 {
    width: 120px;
    height: auto;
  }
}
.img__jgrVp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 164.999px;
  height: 80.999px;
  display: block !important;
  top: auto;
  left: auto;
  flex-shrink: 0;
}
.img__jgrVp > picture > img {
  object-fit: cover;
}
.img__jgrVp > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__jgrVp {
    width: 100px;
    height: auto;
    left: auto;
    top: auto;
  }
}
.section3 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .section3 {
    margin-top: auto !important;
    flex-direction: column;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding-top: 30px;
    flex-column-gap: 0px;
  }
}
.twoSideBox {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .twoSideBox {
    margin-top: 0px;
    width: 100%;
  }
}
.freeBox__n93WH {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__n93WH {
    align-self: auto;
    margin-top: 0px;
    display: flex;
  }
}
.freeBox__buMRa {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__buMRa {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    flex-column-gap: 0px;
  }
}
.columns__vYf4V {
  display: flex;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns__vYf4V > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
.columns__vYf4V > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__vYf4V {
    width: auto;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-self: auto;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__vYf4V > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column__xqsYd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__jnRaU {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.subHeading2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  left: auto;
  top: auto;
  min-width: 0;
}
.text__pvDw6 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Ultra", serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  text-align: left;
  margin-bottom: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__pvDw6 {
    font-size: 30px;
  }
}
.peraContent {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .peraContent {
    font-size: 18px;
  }
}
.unorderList {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.listIteam {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam {
    font-size: 16px;
  }
}
.listIteam3 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam3 {
    font-size: 16px;
  }
}
.listIteam4 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam4 {
    font-size: 16px;
  }
}
.listIteam5 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam5 {
    font-size: 16px;
  }
}
.listIteam6 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam6 {
    font-size: 16px;
  }
}
.listIteam7 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 1.8;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam7 {
    font-size: 16px;
  }
}
.button__ujX:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  margin-top: 26px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .button__ujX:global(.__wab_instance) {
    align-self: center;
  }
}
.svg__fxUzG {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__xI7Uo {
  font-family: "Montserrat", sans-serif;
}
.svg__gwPYy {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.column__w8Aga {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__w8Aga {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    flex-column-gap: 0px;
  }
}
.bannerImage2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  max-height: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .bannerImage2 {
    align-self: center;
  }
}
.imageMarketing2 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.imageMarketing2 > picture > img {
  object-fit: cover;
}
.imageMarketing2 > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .imageMarketing2 {
    width: 400px;
    height: auto;
    flex-shrink: 0;
  }
}
.section4 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#fc5147e0, #fc5147e0),
    url("./images/image3.png") 0% 0% / cover;
  min-width: 0;
  display: none;
  padding: 139px 8px;
}
.freeBox__h1S0 {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__h1S0 {
    width: 100%;
    min-width: 0;
  }
}
.columns__zEhE {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns__zEhE > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 4 / 12);
}
.columns__zEhE > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 8 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__zEhE {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__zEhE > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column__fX6Ss {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.img__v9QWz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 256px;
  height: 256px;
  box-shadow: 0px 0px 0px 16px #ffffffdb;
  flex-shrink: 0;
  border-radius: 999px;
}
.img__v9QWz > picture > img {
  object-fit: cover;
}
.img__v9QWz > .__wab_img-spacer > img {
  object-fit: cover;
}
.column__qtnpR {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__wtjl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.testimonialText {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1px;
  font-size: 40px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .testimonialText {
    max-width: 100%;
    font-size: 30px;
  }
}
.position {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  line-height: 1.5;
  padding-top: 24px;
  min-width: 0;
}
.section5 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 50px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .section5 {
    padding-bottom: 9px;
  }
}
.twoSidedContent {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.subHeading3 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 100%;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .subHeading3 {
    text-align: center;
  }
}
.line2 {
  background: rgba(0, 0, 0, 1);
  width: 104px;
  height: 2px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 30px;
  flex-shrink: 0;
}
.columns__xug8A {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  left: auto;
  top: auto;
  max-width: 1300px;
  min-width: 0;
  padding: 38px 8px 50px;
}
.columns__xug8A > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.columns__xug8A > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.columns__xug8A > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.columns__xug8A > :global(.__wab_flex-container) > *,
.columns__xug8A > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__xug8A > :global(.__wab_flex-container) > picture > img,
.columns__xug8A
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__xug8A {
    width: 100%;
    height: auto;
    flex-direction: row;
    left: auto;
    top: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__xug8A > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__xug8A > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * 16px) * 12 / 12);
  }
}
.column__yokCi {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h2__dkKct {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  padding-left: 0px;
  min-width: 0;
}
.ol {
  display: flex;
  flex-direction: column;
}
.p__rd8Fe {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.column__xbRGv {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h2___06CCc {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.p__jHPem {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.column__cygm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h2__c7AYk {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.p__luTYs {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.column__rSRc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h2__e57No {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.p__toMRn {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.column__lM9RJ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h2__ev7C {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.p__tvy4N {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.column___16N8A {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h2__gNfaS {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 0px;
  min-width: 0;
}
.p__eJhkr {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 20px;
  min-width: 0;
}
.section6 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  display: none;
  padding: 8px;
}
.twoSideBox2 {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.columns__e7Ri6 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns__e7Ri6 > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
.columns__e7Ri6 > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__e7Ri6 {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__e7Ri6 > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column__z6NYo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__rlKz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.subHeading4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  left: auto;
  top: auto;
  min-width: 0;
}
.text__kqrBx {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  text-align: left;
  margin-bottom: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__kqrBx {
    font-size: 30px;
  }
}
.peraContent2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .peraContent2 {
    font-size: 18px;
  }
}
.unorderList2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  min-width: 0;
}
.listIteam2 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam2 {
    font-size: 16px;
  }
}
.listIteam9 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam9 {
    font-size: 16px;
  }
}
.listIteam10 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam10 {
    font-size: 16px;
  }
}
.listIteam11 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam11 {
    font-size: 16px;
  }
}
.listIteam12 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam12 {
    font-size: 16px;
  }
}
.listIteam13 {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  user-select: text;
  white-space: pre-wrap;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .listIteam13 {
    font-size: 16px;
  }
}
.button__wjSZr:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  margin-top: 26px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .button__wjSZr:global(.__wab_instance) {
    align-self: center;
  }
}
.svg__e2XHu {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__c73F {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.column__gHRr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.bannerImage3 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  max-height: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .bannerImage3 {
    align-self: center;
  }
}
.imageMarketing3 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.imageMarketing3 > picture > img {
  object-fit: cover;
}
.imageMarketing3 > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .imageMarketing3 {
    width: 400px;
    height: auto;
    flex-shrink: 0;
  }
}
.section7 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#2f4461cc, #2f4461cc),
    url("./images/image6.png") left 0% top 50% / cover;
  min-width: 0;
  display: none;
  padding: 168px 8px;
}
.freeBox__eXdkf {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.subHeading5 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 100%;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .subHeading5 {
    font-size: 30px;
    text-align: center;
  }
}
.line3 {
  background: #ffffff;
  width: 104px;
  height: 2px;
  display: block;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 30px;
  flex-shrink: 0;
}
.columns___7MYmg {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 1300px;
  min-width: 0;
  padding: 30px 8px;
}
.columns___7MYmg > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.columns___7MYmg > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.columns___7MYmg > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * 16px) * 6 / 12);
}
.columns___7MYmg > :global(.__wab_flex-container) > *,
.columns___7MYmg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns___7MYmg > :global(.__wab_flex-container) > picture > img,
.columns___7MYmg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___7MYmg {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___7MYmg > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___7MYmg > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * 16px) * 12 / 12);
  }
}
.column__s0Kvw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__s0Kvw {
    padding-top: 0px;
    justify-content: flex-start;
  }
}
.blockChapterContent {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .blockChapterContent {
    padding-top: 40px;
  }
}
.text__cKmDk {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__cKmDk {
    font-size: 18px;
  }
}
.li__hvcIp {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  padding-top: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__hvcIp {
    font-size: 16px;
  }
}
.li__e5Ar {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__e5Ar {
    font-size: 16px;
  }
}
.li__wlvIg {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__wlvIg {
    font-size: 16px;
  }
}
.li__g2RWf {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__g2RWf {
    font-size: 16px;
  }
}
.column__d7F1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__d7F1 {
    padding-top: 27px;
    justify-content: flex-start;
  }
}
.blockChapterContent4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
.text___1TqXm {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___1TqXm {
    font-size: 18px;
    padding: 0px 2px 2px;
  }
}
.li__oktnG {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  padding-top: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__oktnG {
    font-size: 16px;
  }
}
.li___6JjN {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li___6JjN {
    font-size: 16px;
  }
}
.li__xpAjz {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__xpAjz {
    font-size: 16px;
  }
}
.li__z2Ty2 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__z2Ty2 {
    font-size: 16px;
  }
}
.column__s3OMt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__s3OMt {
    justify-content: flex-start;
  }
}
.text__kT30P {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__kT30P {
    font-size: 18px;
  }
}
.li__h1Uon {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.li__efUo {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__efUo {
    font-size: 16px;
  }
}
.text___8DJjf {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  line-height: 2;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___8DJjf {
    font-size: 16px;
  }
}
.blockChapterContent2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 8px 8px 0px;
}
.li__iEzQ {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__iEzQ {
    font-size: 16px;
  }
}
.li__jr55T {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__jr55T {
    font-size: 16px;
  }
}
.column__aUqaC {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__aUqaC {
    justify-content: flex-start;
  }
}
.blockChapterContent3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
.text__zcJsz {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__zcJsz {
    font-size: 20px;
  }
}
.li__yqco3 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  padding-top: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__yqco3 {
    font-size: 16px;
  }
}
.li___4Cadn {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li___4Cadn {
    font-size: 16px;
  }
}
.li__owHHk {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__owHHk {
    font-size: 16px;
  }
}
.li__lJUvp {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  line-height: 2;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .li__lJUvp {
    font-size: 16px;
  }
}
.section8 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.columns__o2INc {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 1300px;
  min-width: 0;
  padding: 50px 8px;
}
.columns__o2INc > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 7 / 12);
}
.columns__o2INc > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 5 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__o2INc {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__o2INc > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column___4Umgm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.avtarContent {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px 30px 8px 8px;
}
.h2___4L7NH {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h2___4L7NH {
    padding-top: 30px;
    font-size: 30px;
  }
}
.p__tx8Cy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .p__tx8Cy {
    font-size: 18px;
  }
}
.p__q0IHn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .p__q0IHn {
    font-size: 16px;
  }
}
.p__t60DY {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-weight: 700;
  margin-top: 11px;
  min-width: 0;
  padding: 11px 0px 28px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .p__t60DY {
    font-size: 16px;
  }
}
.p__a4XJ {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: var(--token-Ksk4fqeEjc9aLu);
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .p__a4XJ {
    font-size: 16px;
  }
}
.column__aCxlt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.avatarImage {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
}
.img___4UQfM {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  border-radius: 8px;
}
.img___4UQfM > picture > img {
  object-fit: cover;
}
.img___4UQfM > .__wab_img-spacer > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img___4UQfM {
    height: 100%;
    width: 400px;
    min-height: 0;
  }
}
.section9 {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(#fc5147e0, #fc5147e0),
    url("./images/image10.png") 0% 0% / cover;
  min-width: 0;
  display: none;
  padding: 169px 8px;
}
.freeBox__wHvrU {
  width: 100%;
  max-width: 1300px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
.h2__pAd6X {
  position: relative;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h2__pAd6X {
    font-size: 30px;
    text-align: center;
  }
}
.button__ktXSw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 26px;
}
.svg__bfzfc {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__uUmLb {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
}
.svg {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  color: #ffffff;
}
.section1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 80px;
  align-self: flex-end;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .section1 {
    padding-top: 70px;
  }
}
.container {
  width: 1350px;
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .container {
    width: auto;
  }
}
.freeBox__vPp9 {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__vPp9 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 105px);
  width: calc(100% + 105px);
}
.freeBox__vPp9 > :global(.__wab_flex-container) > *,
.freeBox__vPp9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__vPp9 > :global(.__wab_flex-container) > picture > img,
.freeBox__vPp9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 105px;
}
.freeBox__ggTSo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.img__klbWk {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 4px 8px 24px 0px #00000080;
  display: none !important;
}
.img__klbWk > picture > img {
  object-fit: cover;
}
.img__klbWk > .__wab_img-spacer > img {
  object-fit: cover;
}
.useTicket:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 49px;
  display: none;
}
.svg___3EUgU {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__msW0 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.img__tiDjR {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__tiDjR > picture > img {
  object-fit: cover;
}
.img__tiDjR > .__wab_img-spacer > img {
  object-fit: cover;
}
.flexBox {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: auto;
  flex-shrink: 1;
  min-width: 0;
  padding: 8px;
}
.flexBox > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.flexBox > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * 16px) * 5 / 12);
}
.flexBox > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * 16px) * 7 / 12);
}
.flexBox > :global(.__wab_flex-container) > *,
.flexBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.flexBox > :global(.__wab_flex-container) > picture > img,
.flexBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .flexBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .flexBox > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .flexBox > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * 16px) * 12 / 12);
  }
}
.column__skL05 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.bannerImage {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  max-height: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .bannerImage {
    align-self: center;
  }
}
.img___8MQvO {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 4px 8px 24px 0px #00000080;
  width: 400px;
  flex-shrink: 0;
}
.img___8MQvO > picture > img {
  object-fit: cover;
}
.img___8MQvO > .__wab_img-spacer > img {
  object-fit: cover;
}
.column__vzi9A {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.contentBlock {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px 8px 8px;
}
.mainHeading {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 40px;
  font-weight: 700;
  font-family: "Black Ops One";
  line-height: 1.2;
  letter-spacing: 0px;
  position: relative;
  height: auto;
  max-width: none;
  padding-top: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .mainHeading {
    font-size: 46px;
  }
}
.peraBanner {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.text__rzWs4 {
  position: relative;
  font-family: "Special Elite";
  font-size: 24px;
  font-weight: 500;
  color: var(--token-Ksk4fqeEjc9aLu);
  letter-spacing: 1px;
}
.freeBox__eSNzB {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: auto;
  left: auto;
  z-index: 1;
  align-self: flex-start;
  min-width: 0;
  padding: 0px;
  margin: 20px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__eSNzB {
    top: auto;
    left: auto;
    align-self: center;
  }
}
.button__mOx6O:global(.__wab_instance) {
  max-width: 100%;
}
.svg__dtKH {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__xl1Hv {
  height: auto;
}
.svg__ugF7I {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.footer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 50px 8px;
  border-bottom: 1px solid #d2d2d2;
}
.columns___2RtIb {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 1300px;
  padding: 8px;
}
.columns___2RtIb > :nth-child(3n + 1) {
  width: calc((100% - 3 * 0px) * 5 / 12);
}
.columns___2RtIb > :nth-child(3n + 2) {
  width: calc((100% - 3 * 0px) * 3 / 12);
}
.columns___2RtIb > :nth-child(3n + 3) {
  width: calc((100% - 3 * 0px) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___2RtIb {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___2RtIb > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column__qux7W {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.footerLogo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  min-width: 0;
  padding: 8px;
}
.logo2 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 300px;
}
.logo2 > picture > img {
  object-fit: cover;
}
.logo2 > .__wab_img-spacer > img {
  object-fit: cover;
}
.column___0GDq1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h3__tyZz {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-bottom: 10px;
  padding-top: 10px;
  min-width: 0;
}
.footerLink {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 0;
}
.column__coKz3 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.h3__mgbbt {
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0px;
  line-height: 1.2;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-bottom: 10px;
  padding-top: 10px;
  min-width: 0;
}
.footerLink4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 0;
}
.footerLink5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-family: "Montserrat", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 0;
}
.freeBox__nxFqw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1300px;
  min-width: 0;
  padding: 8px;
}
.columns__b4EJn {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns__b4EJn > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 8 / 12);
}
.columns__b4EJn > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__b4EJn {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__b4EJn > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column__ygnOd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.copyRight {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-Ksk4fqeEjc9aLu);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  min-width: 0;
}
.column__oaT6L {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  display: none;
  padding: 8px;
}
.socialIcons {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.socialIcons > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.socialIcons > :global(.__wab_flex-container) > *,
.socialIcons > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.socialIcons > :global(.__wab_flex-container) > picture > img,
.socialIcons
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
.link__nzHno {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.twitter {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.twitter > picture > img {
  object-fit: cover;
}
.twitter > .__wab_img-spacer > img {
  object-fit: cover;
}
.link__ngWn5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.facebook {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.facebook > picture > img {
  object-fit: cover;
}
.facebook > .__wab_img-spacer > img {
  object-fit: cover;
}
.link__ryJy5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.youtube {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.youtube > picture > img {
  object-fit: cover;
}
.youtube > .__wab_img-spacer > img {
  object-fit: cover;
}
.link___9Yn9W {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.freeBox__ntJw {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.vimeo {
  object-fit: cover;
  max-width: 100%;
  width: 30px;
}
.vimeo > picture > img {
  object-fit: cover;
}
.vimeo > .__wab_img-spacer > img {
  object-fit: cover;
}
